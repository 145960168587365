import React from "react";

const LoadingContext = React.createContext({
    isLoading: false,
    setIsLoading: () => { },
    isLoadingMap: false,
    setIsLoadingMap: () => { },
    mapIsInitialized: false,
    setMapIsInitialized: () => { },

});

export default LoadingContext;