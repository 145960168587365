import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { useLoadLogs } from '../hooks/LoadDataHook'
import * as C from '../helpers/constants';
import * as Utils from '../helpers/utils'
import Spinner from "../ui/Spinner";
import Select from "../ui/Select";
import Input from "../ui/Input";
import Label from "../ui/Label";

const LogGridWrapper = styled.div`
  display: grid;
  grid-template-rows: 2fr;
  grid-template-columns: repeat(9, 1fr);
  padding-left: 0.5rem;
  margin: 0.5rem;
  grid-gap: 0.1rem;
  border: solid 1px ${C.COLOR_PRIMARY};
  font-size: 12px;
`;

const LogStackRow = styled.pre`
    grid-column: 1 / -1
    white-space: pre-wrap;
`;

const LogFilterForm = styled.form`
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 1rem;
    margin: 1rem;
    
    justify-content: center;
`;


const Error = styled.p`
    color: red;
    font-weight: bold;
`;

const initLogFilter = {
    type: "all",
    office: "",
    user: ""
}

const LOG_TYPE_OPTIONS = [{ value: "all", title: "Alla" }, { value: "error", title: "Error" }, { value: "loginError", title: "Login Error" }, { value: "request", title: "Request" }];

const Logs = () => {
    const [logLoad, logData] = useLoadLogs(null);
    const [logFilter, setLogFilter] = useState(initLogFilter)

    const formatText = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1)
    }

    const changeLogTypeHandler = (event) => {
        setLogFilter({ ...logFilter, type: event.target.value })
    }

    const changeLogOfficeHandler = (event) => {
        setLogFilter({ ...logFilter, office: event.target.value })
    }

    const changeLogUserHandler = (event) => {
        setLogFilter({ ...logFilter, user: event.target.value })
    }


    const filterLog = (log) => {
        const withoutLoginErrors = (log) => {
            return !(log.type === "error" &&
                (
                    log.path.includes("login") ||
                    log.path.includes("ErrorLog") && log.message && log.message.includes("No decoded token!")
                ))
        }
        const onlyLoginErrors = (log) => {
            return log.type === "error" &&
                (
                    log.path.includes("login") ||
                    log.path.includes("ErrorLog") && log.message && log.message.includes("No decoded token!")
                )
        }
        const typeFilter = (log) => { return log.type === logFilter.type || logFilter.type === "all" }
        const officeFilter = (log) => {
            log.office = (log.office) ? log.office : "";
            return log.office.startsWith(logFilter.office) || logFilter.office === ""
        }
        const userFilter = (log) => {
            log.user = (log.user) ? log.user : "";
            return log.user && log.user.includes(logFilter.user) || logFilter.user === ""
        }
        if (logFilter.type === "loginError") {
            return log.filter(onlyLoginErrors)
                .filter(officeFilter)
                .filter(userFilter)

        } else if (logFilter.type == "error") {
            return log.filter(typeFilter)
                .filter(withoutLoginErrors)
                .filter(officeFilter)
                .filter(userFilter)
        } else {
            return log.filter(typeFilter)
                .filter(officeFilter)
                .filter(userFilter)
        }
    }

    return (
        <React.Fragment>
            <LogFilterForm>
                <Select name={"select"} options={LOG_TYPE_OPTIONS} initValue={LOG_TYPE_OPTIONS[0].value} changeHandler={(event) => changeLogTypeHandler(event)}></Select>
                <Label label={"Kontor:"} /><Input type={"text"} name={"office"} initialValue={initLogFilter.office} changeHandler={(event) => changeLogOfficeHandler(event)} />
                <Label label={"Användare:"} /><Input type={"text"} name={"user"} initialValue={initLogFilter.user} changeHandler={(event) => changeLogUserHandler(event)} />

            </LogFilterForm>

            {logLoad ? <Spinner /> : <React.Fragment>
                {logData && filterLog(logData).map((log) => {
                    return (
                        <LogGridWrapper key={log._id}>
                            <>
                                <p>{Utils.formattedIsoDateTime(log.date)}</p>
                                {(log.type == "error") ? <Error>{formatText(log.type)}</Error> : <p>{formatText(log.type)}</p>}
                                <p>{log.method.toUpperCase()}</p>
                                <p>{log.version}</p>
                                <p>{log.path}</p>
                                <p>{log.status}</p>
                                <p>{log.user}</p>
                                <p>{log.office}</p>
                                <p>{log.message}</p>

                            </>
                            <LogStackRow>{log.stack}</LogStackRow>
                        </LogGridWrapper>

                    )
                })}
            </React.Fragment>}
        </React.Fragment>
    )
};

export default Logs;
