import React, { useContext, useState, useEffect } from "react";
import styled from 'styled-components';
import * as Utils from '../../helpers/utils';
import MapContext from '../../contexts/MapContext';
import AuthContext from "../../contexts/AuthContext";
import LoadingContext from "../../contexts/LoadingContext";
import Input from '../../ui/Input';
import Label from '../../ui/Label';
import Button from '../../ui/Button';
import * as Req from '../../helpers/requester'

const FormGrid = styled.form`
    display: grid;
    grid-template-rows: auto;
    grid-gap: 0.2rem;
    justify-content: start;
`;

const ButtonGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
    justify-content: start;
`;

const Error = styled.p`
    color: red;
`;

const DistrictForm = () => {
    const mapContext = useContext(MapContext);
    const authContext = useContext(AuthContext);
    const loadingContext = useContext(LoadingContext);
    const [locationError, setLocationError] = useState(null)
    const [nameError, setNameError] = useState(null)
    const [validName, setValidName] = useState(false)

    const validatedName = (name) => {
        return /^\d{4}$/.test(name) && (mapContext.activeMapObject.district.name === name || mapContext.gMap.districts.find(d => d.district.name === name) === undefined)
    }

    useEffect(() => {
        mapContext.activeMapObject.marker.setLabel({ ...mapContext.activeMapObject.marker.label, text: mapContext.activeMapObject.district.name || "Distriktsnamn" })
        setValidName(validatedName(mapContext.activeMapObject.district.name))
    }, [mapContext.activeMapObject.district])

    const districtNameChangeHandler = (event) => {
        mapContext.setIsEditingActiveMapObjectHandler(true);
        mapContext.activeMapObject.marker.setLabel({ ...mapContext.activeMapObject.marker.label, text: event.target.value || "Distriktsnamn" })
        setValidName(validatedName(event.target.value))
    }

    const updateMapObject = (data) => {
        mapContext.activeMapObject[mapContext.activeMapObject.type] = data
    }


    const submitHandler = (event) => {
        event.preventDefault();
        setValidName(validatedName(mapContext.activeMapObject.marker.label.text))
        if (!mapContext.activeMapObject.marker.getVisible()) {
            setLocationError("Placera ut distriktets namn genom att högerklicka inom distriktet")
            return;
        } else {
            setLocationError(null)
        }
        if (!validName) {
            setNameError("Måste fylla i ett unikt distriktsnamn med fyra siffror")
            return;
        } else {
            setNameError(null)
        }

        const mapObject = mapContext.activeMapObject
        mapObject.district.name = mapContext.activeMapObject.marker.label.text;
        mapObject.district.frameCenterLocation = mapContext.activeMapObject.marker.getPosition()
        mapObject.district.frame = mapContext.activeMapObject.getPath().getArray()

        loadingContext.setIsLoading(true)
        if (mapContext.activeMapObject[mapContext.activeMapObject.type]._id === undefined) {
            Req.postMapObject(mapObject.district, "district")
                .then(result => {
                    updateMapObject(result.data)
                    mapContext.gMap.add(mapObject)
                    mapContext.clearMapSelection()
                    loadingContext.setIsLoading(false)

                }).catch(error => {
                    authContext.errorHandler(error.response)
                    loadingContext.setIsLoading(false)
                });
        } else {
            Req.updateMapObject(mapObject.district, "district")
                .then(result => {
                    updateMapObject(result.data)
                    mapContext.gMap.update(mapObject)
                    mapContext.clearMapSelection()
                    loadingContext.setIsLoading(false)
                })
                .catch(error => {
                    authContext.errorHandler(error.response)
                    loadingContext.setIsLoading(false)
                });
        }
    }

    const abortHandler = (event) => {
        event.preventDefault();
        mapContext.gMap.undo(mapContext.activeMapObject)
        mapContext.clearMapSelection()
    }

    const deleteWarning = (event) => {
        event.preventDefault();
        const district = mapContext.activeMapObject.district;
        const routeString = `${(district.routes.length > 0) ? `${district.routes.length} leveransrutt${(district.routes.length != 1) ? "er" : ""}` : ""}\n`
        const masterString = `${(district.masters.length > 0) ? `${district.masters.length} huvudrutt${(district.masters.length != 1) ? "er" : ""}` : ""}\n`
        const markerString = `${(district.markers.length > 0) ? `${district.markers.length} markering${(district.markers.length != 1) ? "ar" : ""}` : ""}\n`
        return window.confirm(`Är du säker på att du vill ta bort distrikt ${district.name} som innehåller:${routeString}${masterString}${markerString}`);
    }

    const deleteHandler = (event) => {
        const mapObject = mapContext.activeMapObject
        loadingContext.setIsLoading(true)
        Req.deleteMapObject(mapObject[mapContext.activeMapObject.type], "district")
            .then(result => {
                mapContext.gMap.remove(mapObject)
                mapContext.clearMapSelection()
                loadingContext.setIsLoading(false)
            }).catch(error => {
                authContext.errorHandler(error.response)
                loadingContext.setIsLoading(false)
            });
    }

    return (
        <FormGrid onSubmit={(event) => submitHandler(event)}>
            {locationError ? (<Error>{locationError}</Error>) : null}
            <Input
                type={"text"}
                name={"name"}
                initialValue={mapContext.activeMapObject.district.name}
                changeHandler={(event) => districtNameChangeHandler(event)}
                maxlength={4}
                regex={/^[0-9\b]+$/}
                placeholder={"Distriktsnamn"} >
            </Input>
            {nameError ? (<Error>{nameError}</Error>) : null}
            <ButtonGrid>
                {mapContext.isEditingActiveMapObject ?
                    (
                        <React.Fragment>
                            <Button type={"submit"} label={"Spara"}></Button>
                            <Button type={"button"} label={"Avbryt"} clickHandler={(event) => abortHandler(event)}></Button>
                        </React.Fragment>

                    ) : (
                        <Button type={"button"} label={"Radera"} clickHandler={(event) => (deleteWarning(event)) && deleteHandler(event)}></Button>)
                }
            </ButtonGrid>
        </FormGrid>
    );
};

export default DistrictForm;
