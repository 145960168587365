import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const InputContainer = styled.div`

`

const StyledInput = styled.input`
    margin-right: 10px;
    width: 90%

`

const Input = ({ type, name, initialValue, changeHandler, maxlength, regex, placeholder }) => {
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const inputChangeHandler = (event) => {
        if (regex == undefined) {
            setValue(event.target.value);
            changeHandler(event);
            return
        }

        if (event.target.value === '' || regex.test(event.target.value)) {
            setValue(event.target.value);
            changeHandler(event);
        }
    }

    return (
        <InputContainer>
            <StyledInput placeholder={placeholder} type={type} name={name} value={value} onChange={(event) => inputChangeHandler(event)} maxLength={maxlength != undefined ? maxlength : 100}></StyledInput>
        </InputContainer>
    )
}

export default Input;
