
import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { jwtDecode } from "jwt-decode";
import Grid from "./Grid";
import Auth from "./pages/Auth";
import AuthContext from "./contexts/AuthContext";
import LoadingContext from "./contexts/LoadingContext";
import * as C from './helpers/constants';
import { BrowserRouter } from "react-router-dom";

const clearLocalStorage = () => {
  localStorage.removeItem(C.TOKEN);
};

const updateLocalStorageWithToken = token => {
  localStorage.setItem(C.TOKEN, token);
};

const App = props => {
  const [authStatus, setAuthStatus] = useState(false);
  const [role, setRole] = useState(0);
  const [loginError, setLoginError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMap, setIsLoadingMap] = useState(true);
  const [mapIsInitialized, setMapIsInitialized] = useState(false);

  useEffect(() => {
    document.title = "SDR Admin"
  }, []);

  const updateStateLoggedIn = (token) => {
    updateLocalStorageWithToken(token);
    authStatusHandler(true);
    authRoleHandler(jwtDecode(token).role)
    errorMessageHandler(null);
  }

  const updateStateLoggedOutWithMessage = (errorMessage) => {
    errorMessageHandler(errorMessage);
    authStatusHandler(false);
    authRoleHandler(0)
    clearLocalStorage();
  }

  const logout = event => {
    updateStateLoggedOutWithMessage(null);
  };

  const authStatusHandler = (status) => {
    setAuthStatus(status);
  }

  const authRoleHandler = (role) => {
    setRole(role);
  }

  const errorMessageHandler = (message) => {
    setLoginError(message);
  }

  const isLoadingHandler = (value) => {
    setIsLoading(value);
  }

  const isLoadingMapHandler = (value) => {
    setIsLoadingMap(value);
  }

  const mapIsInitializedHandler = (value) => {
    setMapIsInitialized(value);
  }

  const errorHandler = (response) => {
    if (response) {
      switch (response.status) {
        case 401:
          alert("Ogiltliga referenser. Loggas ut...")
          updateStateLoggedOutWithMessage("Obehörig åtkomst. Logga in igen")
          break;
        case 403:
          alert("Felaktiga uppgifter. Felrapport skapad! Loggas ut...")
          updateStateLoggedOutWithMessage("Fel uppstod. Logga in igen")
          break;
        case 404:
          alert("Hittar ingen data. Felrapport skapad! Loggas ut...")
          updateStateLoggedOutWithMessage("Fel uppstod. Logga in igen")
          break;
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
          alert("Servern svarar ej. Loggas ut...")
          updateStateLoggedOutWithMessage("Servern svarar ej")
          break;
        default:
          alert("Okänt fel. Loggas ut...")
          updateStateLoggedOutWithMessage("Okänt fel")
      }
    } else {

    }

  }

  return (
    <LoadingContext.Provider
      value={{
        isLoading: isLoading,
        setIsLoading: isLoadingHandler,
        isLoadingMap: isLoadingMap,
        setIsLoadingMap: isLoadingMapHandler,
        mapIsInitialized: mapIsInitialized,
        setMapIsInitialized: mapIsInitializedHandler,

      }}
    >
      <AuthContext.Provider
        value={{
          isAuthenticated: authStatus,
          role: role,
          updateStateLoggedIn: updateStateLoggedIn,
          updateStateLoggedOutWithMessage: updateStateLoggedOutWithMessage,
          logout: logout,
          loginError: loginError,
          errorHandler: errorHandler
        }}
      >
        <BrowserRouter>
          {authStatus === true ?
            (
              <Grid />
            )
            :
            (
              <Routes>
                <Route path="/login" element={<Auth />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            )}
        </BrowserRouter>
      </AuthContext.Provider>
    </LoadingContext.Provider>
  );
};

export default App;
