import React, { useState, useEffect, useContext } from "react";
import GoogleMapsApiLoader from "google-maps-api-loader";
import { jwtDecode } from 'jwt-decode'

import * as C from '../helpers/constants'
import polylineExtension from '../extensions/polylineExtension'
import polygonExtension from '../extensions/polygonExtension'
import markerExtension from '../extensions/markerExtension'
import mapExtension from '../extensions/mapExtension'
import MapContext from '../contexts/MapContext'

const useGoogleMap = () => {
    const [googleMap, setGoogleMap] = useState(null);
    useEffect(() => {
        GoogleMapsApiLoader({ libraries: GOOGLE_LIBRARIES, apiKey: API_KEY }).then(google => {

            setGoogleMap(google);
        });
    }, []);
    return googleMap;
};

const useMap = (googleMap, mapContainerRef, properties) => {
    const [map, setMap] = useState(null);
    const mapContext = useContext(MapContext)
    useEffect(() => {
        if (!googleMap || !mapContainerRef.current) {
            return;
        }
        const MAP_CONFIG = {
            zoom: 6,
            center: { lat: 57.732042, lng: 11.7908 },
            scaleControl: true,
            options: {
                gestureHandling: 'greedy'
            }
        };

        const gMap = new googleMap.maps.Map(mapContainerRef.current, MAP_CONFIG);

        if (properties != null) {
            for (const [key, value] of Object.entries(properties)) {
                gMap[key] = value
            }
        }
        gMap.office = jwtDecode(localStorage.getItem(C.TOKEN)).office

        polylineExtension(googleMap, gMap, mapContext)
        polygonExtension(googleMap, gMap, mapContext)
        mapExtension(googleMap, gMap, mapContext)
        markerExtension(googleMap, gMap, mapContext)
        setMap(gMap);
    },
        [googleMap, mapContainerRef]
    );
    return map;
};

const useDrawingManager = (googleMap, gMap) => {
    const [drawingManager, setDrawingManager] = useState(null);
    useEffect(() => {
        if (!googleMap || !gMap) {
            return;
        }

        const manager = new googleMap.maps.drawing.DrawingManager({
            drawingMode: null,
            markerOptions: markerOptions(googleMap),
            polylineOptions: polylineOptions(),
            polygonOptions: polygonOptions(),
            drawingControl: false,
            drawingControlOptions: {
                position: googleMap.maps.ControlPosition.BOTTOM_LEFT,
                drawingModes: ['marker', 'polygon', 'polyline']
            }

        });
        manager.setMap(gMap);
        setDrawingManager(manager)

    }, [googleMap, gMap])
    return drawingManager
}

const GOOGLE_LIBRARIES = ['places', 'drawing', 'geometry'];
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const markerOptions = (googleMap) => {
    return {
        zIndex: 117,
        type: "marker",
        icon: {
            path: googleMap.maps.SymbolPath.CIRCLE,
            strokeColor: "#000000",
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: "#000000",
            fillOpacity: 0.3,
            radius: 5,
            scale: 7,
        }
    }
}

const polylineOptions = () => {
    return {
        zIndex: 116,
        type: "master",
        strokeColor: C.MASTER_EDIT_STROKE_COLOR,
        strokeOpacity: C.MASTER_STROKE_OPACITY,
        strokeWeight: C.MASTER_LINE_WIDTH,
        editable: true,
        endPoints: [],
        flagpoints: [],
        routeChanges: [],
    }

}

const polygonOptions = () => {
    return {
        zIndex: 114,
        type: "district",
        strokeColor: C.DISTRICT_EDIT_STROKE_COLOR,
        strokeOpacity: C.DISTRICT_STROKE_OPACITY,
        strokeWeight: C.DISTRICT_LINE_WIDTH,
        fillColor: C.DISTRICT_EDIT_FILL_COLOR,
        fillOpacity: C.DISTRICT_EDIT_FILL_OPACITY,
        editable: true,
    }

}


export { useGoogleMap, useMap, useDrawingManager };