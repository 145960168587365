import React, { useContext } from "react";
import styled from "styled-components";
import * as C from '../../helpers/constants';

import Navigation from "./navigation/Navigation";
import AuthContext from "../../contexts/AuthContext";

const HeaderGrid = styled.div`
  display: grid;
  color: #eee;
  grid-template-columns: 150px 1fr 100px;
`;

const BrandGrid = styled.div`
  background-color: ${C.COLOR_PRIMARY};
`;

const NavigationGrid = styled.div`
  background-color: ${C.COLOR_PRIMARY};
`;

const UserGrid = styled.div`
  background-color: ${C.COLOR_PRIMARY};
`;

const LogoutLink = styled.a`
  display: block;
  color: #eee;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  .active,
  :active {
    color: black;
  }
`;

const Header = () => {
  const authContext = useContext(AuthContext);
  return (
    <HeaderGrid>
      <BrandGrid>v.{C.VERSION}</BrandGrid>
      <NavigationGrid>
        <Navigation />
      </NavigationGrid>
      <UserGrid>
        {authContext.isAuthenticated === true ? (
          <LogoutLink onClick={authContext.logout}>Logga ut</LogoutLink>
        ) : (
            <h1>UserGrid</h1>
          )}
      </UserGrid>
    </HeaderGrid>
  );
};

export default Header;
