import { jwtDecode } from "jwt-decode";

import * as C from './constants';


export const validateRouteDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDay()
    const hour = date.getHours() - 1
    switch (day) {
        case 5:
            return hour >= 18
        case 6:
            return true
        case 0:
            return hour <= 14
        default:
            return false
    }
}

export const getWeekNumber = (dateString) => {
    var date = new Date(dateString)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    var weekNr = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
    return weekNr;
}

export const decodedToken = () => {
    return jwtDecode(localStorage.getItem(C.TOKEN));
}

export const formatDuration = (ms) => {
    return msToTimeString(ms);
}

const degreesToRadians = (degrees) => {
    return degrees * Math.PI / 180;
}

const distanceInKmBetweenEarthCoordinates = (lat1, lon1, lat2, lon2) => {
    var earthRadiusKm = 6371;

    var dLat = degreesToRadians(lat2 - lat1);
    var dLon = degreesToRadians(lon2 - lon1);

    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadiusKm * c;
}

export const routeInKm = (route) => {
    var len = route.length, dist = 0;
    for (var i = 0; i < len - 1; i++) {
        dist += distanceInKmBetweenEarthCoordinates(route[i].lat, route[i].lng, route[i + 1].lat, route[i + 1].lng);
    }

    return Math.round(dist * 10) / 10
}

const msToTimeString = (ms) => {
    let seconds = Math.floor((ms / 1000) % 60);
    let minutes = Math.floor((ms / (1000 * 60)) % 60);
    let hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
}

export const TimeStringToMs = (timeString) => {
    let str = timeString.split(':').join('')
    let hours = parseInt(str.substring(0, 2)) * 1000 * 60 * 60
    let minutes = parseInt(str.substring(2, 4)) * 1000 * 60
    let seconds = parseInt(str.substring(4, 6)) * 1000
    return hours + minutes + seconds
}

export const formattedIsoDate = (isoDate) => {
    return isoDate.substring(0, 10)
}

export const formattedIsoDateTime = (isoDate) => {
    return isoDate.substring(0, 19).replace('T', ' ')
}

export const arraysAreEqual = (array1, array2) => {
    return array1.every(e => array2.includes(e)) && array1.every(e => array2.includes(e))
}

export const chunkArrayInGroups = (arr, size) => {
    var myArray = [];
    for (var i = 0; i < arr.length; i += size) {
        myArray.push(arr.slice(i, i + size));
    }
    return myArray;
}

export const chunkArrayInGroupsWithEnding = (arr, size) => {
    var myArray = [];
    for (var i = 0; i < arr.length; i += size) {
        if (i === 0) {
            myArray.push(arr.slice(i, i + size));
        } else {
            myArray.push(arr.slice(i - 1, i + size));
        }

    }
    return myArray;
}

export const spreadIndexRangeEqual = (routeArray, closestPoint, maxPoints) => {
    const length = routeArray.length
    const middleIndex = parseInt(closestPoint.index)
    const elementCount = ((maxPoints - 1) / 2)

    const lowDiff = middleIndex - elementCount
    const start = (lowDiff >= 0) ? lowDiff : 0
    const highDiff = length - (middleIndex + elementCount)
    const end = (highDiff >= 0) ? middleIndex + elementCount : length
    return [start, end]
}



export const getDate = () => {
    return new Date().toISOString();
}

export const convertNewRoutePathToRouteCoordinates = (routePath) => {
    var route = [];
    for (const [index, point] of routePath.getArray().entries()) {
        let routeCoordinate = {
            bearing: undefined,
            time: undefined,
            lat: point.lat(),
            lng: point.lng(),
        };
        route.push(routeCoordinate);
    }

    return route;
};

export const addMapObjectListeners = (googleMap, gMap, mapObject, listeners) => {
    if (mapObject.listeners) {
        for (const listener of mapObject.listeners) {
            googleMap.maps.event.removeListener(listener);
        }
    }
    const addedListeners = [];
    for (const listener of listeners) {
        if (listener === "set_at" || listener === "insert_at" || listener === "mouseover") {
            addedListeners.push(googleMap.maps.event.addListener(mapObject.getPath(), listener, (event) => {
            }))
        } else {
            addedListeners.push(googleMap.maps.event.addListener(mapObject, listener, (event) => {
            }))
        }

    }
    return addedListeners;
}

export const convertDistrictPathToFrameCoordinates = (districtPath) => {
    var frameCoordinates = [];
    for (const latLng of districtPath.getArray()) {
        var coordinate = {
            lat: latLng.lat(),
            lng: latLng.lng()
        };
        frameCoordinates.push(coordinate);
    }
    return frameCoordinates;
};


export const convertRoutePathToRouteCoordinates = (routePath) => {
    var route = [];
    for (const [index, point] of routePath.getArray().entries()) {
        route.push({
            bearing: point.bearing,
            time: point.time,
            lat: point.lat(),
            lng: point.lng(),
        })
    }
    return route;
};

export const updateNewRoutePathWithData = (routePath) => {
    for (const point of routePath.getArray()) {
        point.bearing = point.bearing || undefined
        point.time = point.time || undefined
        point.lat = point.lat()
        point.lng = point.lng()
    }
}

const distanceBetween = (fromPoint, toPoint) => {
    var e = Math, ra = e.PI / 180;
    var b = fromPoint.lat * ra, c = toPoint.lat * ra, d = b - c;
    var g = fromPoint.lng * ra - toPoint.lng * ra;
    var f = 2 * e.asin(e.sqrt(e.pow(e.sin(d / 2), 2) + e.cos(b) * e.cos(c) * e.pow(e.sin(g / 2), 2)));
    return f * 6378137;
}

const toRadians = (point) => {
    return [point.lat * Math.PI / 180, point.lng * Math.PI / 180];
};


const bearing = (startPoint, endPoint) => {
    let [startLat, startLng] = toRadians(startPoint);
    let [destLat, destLng] = toRadians(endPoint);

    let y = Math.sin(destLng - startLng) * Math.cos(destLat);
    let x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = brng * 180 / Math.PI;
    return (brng + 360) % 360;
}

const filterRouteByBearing = (path, degreesFilter) => {
    let previousPoint = {}
    let previousBearing = {}
    let filteredPath = []
    for (const [index, point] of Object.entries(path)) {
        let shouldSave = true
        if (index > 1) {
            const distance = distanceBetween(previousPoint, point)
            const bearingDiff = Math.abs(previousBearing - bearing(previousPoint, point))

            if ((bearingDiff > degreesFilter && bearingDiff < (360 - degreesFilter)) || distance > C.FILTER_DISTANCE) {

                filteredPath.push(point)
                previousBearing = bearing(previousPoint, point)
                previousPoint = point
            }
        }
        if (index === 1) {
            previousBearing = bearing(previousPoint, point)
            previousPoint = point
        }

        if (index === 0) {
            previousPoint = point
        }
    }
    return filteredPath
}

export const point2LatLng = (point, googleMap, gMap) => {
    let topRight = gMap.getProjection().fromLatLngToPoint(gMap.getBounds().getNorthEast());
    let bottomLeft = gMap.getProjection().fromLatLngToPoint(gMap.getBounds().getSouthWest());
    let scale = Math.pow(2, gMap.getZoom());
    let worldPoint = new googleMap.maps.Point(
        point.x / scale + bottomLeft.x,
        point.y / scale + topRight.y
    );
    return gMap.getProjection().fromPointToLatLng(worldPoint);
};

