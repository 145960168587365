import React, { useRef, useContext, useState, useEffect } from "react";
import styled from "styled-components";

import { useLoadOffices } from '../hooks/LoadDataHook'
import * as C from '../helpers/constants'
import AuthContext from "../contexts/AuthContext";
import Checkbox from "../ui/Checkbox";
import Select from "../ui/Select";
import * as Req from '../helpers/requester';
import Spinner from "../ui/Spinner";

const Grid = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: ${C.COLOR_SECONDARY};
`;

const Version = styled.div`
  height: 50px;
  padding: 0px;
  margin: 0px;
`;

const ErrorLabel = styled.p`
  text-align: center;
  color: ${C.COLOR_PRIMARY};
`;

const WrappingGrid = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 0.2rem;
  min-height: calc(100vh - 50px);
`;

const LoginWrapper = styled.form`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 0.2rem;
`;


const LoginButton = styled.button`
  float: right;
  background-color: transparent;
  border: solid 2px ${C.COLOR_PRIMARY};
  border-radius: 7px;
  color: ${C.COLOR_PRIMARY};
  outline: none;
  cursor: pointer;
  font: inherit;
  padding: 10px;
  margin: 10px;
  font-weight: bold;
  &:hover {
    color: white;
    background-color: ${C.COLOR_PRIMARY};
  }
`;

const StyledInput = styled.input`
  outline: none;
  background-color: white;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;

const formElement = {
  email: {
    elementType: "email",
    placeholder: "Användarnamn"
  },
  password: {
    elementType: "password",
    placeholder: "Lösenord"
  }
};

const Auth = () => {
  const authContext = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false)
  const [offices, setOffices] = useState(null)
  const [choosenOffice, setChoosenOffice] = useState(null)
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const [officeLoad, officeData] = useLoadOffices(null)

  useEffect(() => {
    if (!officeData) {
      return
    }
    const officeObj = officeData.map(officeNumber => ({ value: officeNumber, title: officeNumber }))
    setOffices(officeObj)
    setChoosenOffice(officeObj[0].value)
  }, [officeData])

  const checkboxChangeHandler = (name, isChecked) => {
    setIsAdmin(isChecked)
  }

  const changeHandler = (event) => {
    setChoosenOffice(event.target.value)
  }

  const loginPressed = (event) => {
    event.preventDefault();
    Req.login(
      emailInputRef.current.value,
      passwordInputRef.current.value,
      isAdmin,
      choosenOffice
    )
      .then(result => {
        authContext.updateStateLoggedIn(result.data);
      })
      .catch(error => {
        loginErrorHandler(error.response)
      });
  }

  const loginErrorHandler = (response) => {
    if (response) {
      switch (response.status) {
        case 401:
          authContext.updateStateLoggedOutWithMessage("Sessionen har utgått. Logga in igen")
          break;
        case 403:
          authContext.updateStateLoggedOutWithMessage("Ogiltliga inloggnings uppgifter")
          break;
        case 404:
          authContext.updateStateLoggedOutWithMessage("Ogiltliga inloggnings uppgifter")
          break;
        case 500:
          authContext.updateStateLoggedOutWithMessage("Servern svarar ej")
          break;
        default:
          authContext.updateStateLoggedOutWithMessage("Okänt fel")
      }
    } else {
    }

  }



  return (
    <>
      <Version>v.{C.VERSION}</Version>
      <WrappingGrid>
        <Grid>
          {(officeLoad) ? <Spinner /> : <LoginWrapper>
            <ErrorLabel>{authContext.loginError}</ErrorLabel>
            <StyledInput
              type={formElement.email.elementType}
              placeholder={formElement.email.placeholder}
              ref={emailInputRef}
            />
            <StyledInput
              type={formElement.password.elementType}
              placeholder={formElement.password.placeholder}
              ref={passwordInputRef}
            />
            {(isAdmin) ? (<Select name={"office"} options={offices} changeHandler={(event) => changeHandler(event)}></Select>) : null}
            <LoginButton onClick={event => loginPressed(event)}>Logga in</LoginButton>

            <Checkbox label={"Admin"} name={"admin"} value={false} checked={false} checkboxChangeHandler={(name, isChecked) => checkboxChangeHandler(name, isChecked)}></Checkbox>

          </LoginWrapper>}

        </Grid>

      </WrappingGrid>

    </>
  );
};

export default Auth;
