import React from "react";

const AuthContext = React.createContext({
  isAuthenticated: false,
  role: 1,
  polyline: [],
  login: () => { },
  logout: () => { },
  errorHandler: () => { }
});

export default AuthContext;
