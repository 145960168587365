import React from "react";
import styled from 'styled-components';
import { useLoadActions } from '../hooks/LoadDataHook'
import * as C from '../helpers/constants';
import * as Utils from '../helpers/utils'
import Spinner from "../ui/Spinner";

const LogGridWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(7, 1fr);
  padding-left: 0.5rem;
  margin: 0.5rem;
  grid-gap: 0.2rem;
  border: solid 1px ${C.COLOR_PRIMARY};
  font-size: 12px;
`;

const Home = () => {
  const [actionLoad, actionData] = useLoadActions(null);

  const formatObjectType = (type) => {
    switch (type) {
      case "marker":
        return "Markering"
      case "district":
        return "Distrikt"
      case "route":
        return "Leveransrutt"
      case "master":
      case "activateMaster":
      case "masterFlag":
        return "Huvudrutt"

      default:
    }
  }

  const formatMethod = (method) => {
    switch (method) {
      case "put":
        return "Ändrad"
      case "post":
        return "Skapad"
      case "delete":
        return "Borttagen"
      default:
    }
  }

  return (
    <React.Fragment>
      <h1>Välkommen {Utils.decodedToken().firstName}!</h1>
      {actionLoad ? <Spinner /> : <React.Fragment>
        {actionData && actionData.map((action) => {
          return (
            <LogGridWrapper key={action._id}>
              <p>{Utils.formattedIsoDateTime(action.date)}</p>
              <p>{(action.device === "web") ? "Hemsida" : "Mobil"}</p>
              <p>{action.user}</p>
              <p>{action.office}</p>
              <p>{action.district}</p>
              <p>{formatObjectType(action.object)}</p>
              <p>{formatMethod(action.method)}</p>

            </LogGridWrapper>
          )
        })}
      </React.Fragment>}
    </React.Fragment>
  )
};

export default Home;
