
import React, { useState, useEffect, useContext } from "react";
import styled from 'styled-components';
import * as Req from '../helpers/requester';
import MapContext from '../contexts/MapContext'
import AuthContext from '../contexts/AuthContext'
import * as Utils from '../helpers/utils'
import * as C from "../helpers/constants";
import LoadingContext from "../contexts/LoadingContext";
import Button from '../ui/Button';

const PresenterWrapper = styled.div`
`;

const GridRows = styled.div`
`;

const Img = styled.img`
    width:80%;
`;

const P = styled.p`
    padding: 0;
    margin: 0;
    font-size: 12px;
`;

const Bold = styled.p`
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 12px;
`;


const Presenter = (props) => {
    const mapContext = useContext(MapContext);
    const authContext = useContext(AuthContext);
    const loadingContext = useContext(LoadingContext);
    const [image, setImage] = useState(null);
    const [distance, setDistance] = useState(null);
    const [date, setDate] = useState(null);
    const [district, setDistrict] = useState(null);
    const [duration, setDuration] = useState(null);

    const buildPresenterDistrict = (mapDistrict) => {
        const presenterDistrict = {
            masters: [],
            routes: [],
        }

        for (const mapMaster of mapDistrict.mapMasters) {
            const master = mapMaster.master
            if (master.isActive) {
                const presenterObj = {
                    type: "Huvudrutt",
                    date: Utils.formattedIsoDate(master.date),
                    distance: Math.round(Utils.routeInKm(master.route) * 10) / 10,
                    duration: Utils.formatDuration(master.duration),
                    key: master._id,
                }
                presenterDistrict.masters.push(presenterObj)
            }
        }

        for (const [index, mapRoute] of [...mapDistrict.mapRoutes].reverse().entries()) {
            if (index < 2) {
                const route = mapRoute.route
                const presenterObj = {
                    type: "Följd rutt",
                    date: Utils.formattedIsoDate(route.date),
                    distance: Math.round(Utils.routeInKm(route.route) * 10) / 10,
                    duration: Utils.formatDuration(route.duration),
                    key: route._id,
                }
                presenterDistrict.routes.push(presenterObj)
            }
        }
        return presenterDistrict;
    }

    useEffect(() => {
        setImage(null);
        switch (mapContext.activeMapObject.type) {
            case "marker":
                if (!mapContext.activeMapObject.marker.img || mapContext.activeMapObject.marker.img === "") {
                    setImage(null);
                    return;
                }
                Req.getImage(mapContext.activeMapObject.marker.img)
                    .then(result => {
                        const img = URL.createObjectURL(result.data);
                        setImage(img);

                    })
                    .catch(error => {
                        authContext.errorHandler(error.response)
                    });
                setDate(Utils.formattedIsoDate(mapContext.activeMapObject.marker.date))
                break;
            case "route":
                setDistance(Math.round(Utils.routeInKm(mapContext.activeMapObject.route.route) * 10) / 10)
                setDate(Utils.formattedIsoDate(mapContext.activeMapObject.route.date))
                setDuration(Utils.formatDuration(mapContext.activeMapObject.route.duration))
                break;
            case "master":
                setDistance(Math.round(Utils.routeInKm(mapContext.activeMapObject.master.route) * 10) / 10)
                setDate(Utils.formattedIsoDate(mapContext.activeMapObject.master.date))
                setDuration(Utils.formatDuration(mapContext.activeMapObject.master.duration))
                break;
            case "district":
                setDistrict(buildPresenterDistrict(mapContext.activeMapObject))
                break;
            default:
        }

    }, [mapContext.activeMapObject])

    const adminDeleteRouteHandler = (event) => {
        event.preventDefault();
        const mapObject = mapContext.activeMapObject
        loadingContext.setIsLoading(true)
        Req.deleteMapObject(mapObject[mapContext.activeMapObject.type], "route")
            .then(result => {
                mapContext.gMap.remove(mapObject)
                mapContext.clearMapSelection()
                loadingContext.setIsLoading(false)
            }).catch(error => {
                authContext.errorHandler(error.response)
                loadingContext.setIsLoading(false)
            });
    }

    const resetHandler = (event) => {
        event.preventDefault();
        const mapObject = mapContext.activeMapObject
        loadingContext.setIsLoading(true)
        Req.resetMean(mapObject[mapContext.activeMapObject.type], "route")
            .then(result => {
                mapContext.gMap.update(mapObject)
                mapContext.clearMapSelection()
                loadingContext.setIsLoading(false)
            }).catch(error => {
                authContext.errorHandler(error.response)
                loadingContext.setIsLoading(false)
            });
    }

    return (
        <PresenterWrapper>
            {mapContext.activeMapObject.type === "master" ? (
                <GridRows>
                    <P>Skapad: {date}</P>
                    <P>Godkänd: {mapContext.activeMapObject[mapContext.activeMapObject.type].isValid ? "Ja" : "Nej"}</P>
                    <P>Aktiv: {mapContext.activeMapObject[mapContext.activeMapObject.type].isActive ? "Ja" : "Nej"}</P>
                    <P>Tid: {duration}</P>
                    <P>Distans: {distance} km</P>
                </GridRows>
            ) : (
                    null
                )}
            {mapContext.activeMapObject.type === "route" ? (
                <GridRows>
                    <P>Skapad: {date}</P>
                    <P>Tid: {duration}</P>
                    <P>Distans: {distance} km</P>
                    {parseInt(authContext.role) >= parseInt(C.SUPER_ADMIN) ?
                        <Button type={"button"} label={"Radera"} clickHandler={(event) => adminDeleteRouteHandler(event)}></Button> :
                        null}

                </GridRows>
            ) : (
                    null
                )}
            {mapContext.activeMapObject.type === "marker" ? (
                <GridRows>
                    <Img src={image} alt={""} />
                    <P>Markeringstyp: {mapContext.activeMapObject[mapContext.activeMapObject.type].type === "mailbox" ? "Viktig Brevlåda" : "Informationspunkt"}</P>
                    <P>Skapad: {date}</P>
                    <P>Adress: {mapContext.activeMapObject[mapContext.activeMapObject.type].address}</P>
                    <P>Meddelande: {mapContext.activeMapObject[mapContext.activeMapObject.type].message}</P>
                    <P>Godkänd: {mapContext.activeMapObject[mapContext.activeMapObject.type].isValid ? "Ja" : "Nej"}</P>

                </GridRows>
            ) : (
                    null
                )}
            {mapContext.activeMapObject.type === "district" ? (
                <GridRows>
                    <P>Distrikt: {mapContext.activeMapObject[mapContext.activeMapObject.type].name}</P>
                    <P>Namn: {(mapContext.activeMapObject[mapContext.activeMapObject.type].data.postalName).substring(6)}</P>
                    <P>Beskrivning: {mapContext.activeMapObject[mapContext.activeMapObject.type].data.districtDescription}</P>
                    <P>Hushåll total: {mapContext.activeMapObject[mapContext.activeMapObject.type].data.rentals}</P>
                    <P>Hushåll ja tack: {mapContext.activeMapObject[mapContext.activeMapObject.type].data.rentalsAccepting}</P>
                    <P>Leverans snittid: {Utils.formatDuration(mapContext.activeMapObject[mapContext.activeMapObject.type].meanDuration)}</P>
                    <P>Leverans snittlängd: {mapContext.activeMapObject[mapContext.activeMapObject.type].meanDistance} km</P>

                    <br />
                    <Bold>Aktiv master:</Bold>
                    {district ? district.masters.map((master) => {
                        return (
                            <GridRows key={master.key}>
                                <P>Skapad: {master.date}</P>
                                <P>Tid: {master.duration}</P>
                                <P>Distans: {master.distance} km</P>
                            </GridRows>
                        )
                    }) : null}
                    <br />
                    <Bold>Senaste Leveranser:</Bold>
                    {district ? district.routes.map((route) => {
                        return (
                            <GridRows key={route.key}>
                                <P>Skapad: {route.date}</P>
                                <P>Tid: {route.duration}</P>
                                <P>Distans: {route.distance} km</P>
                                <br />
                            </GridRows>
                        )
                    }) : null}
                    <Button type={"button"} label={"Nolställ snitt"} clickHandler={(event) => resetHandler(event)}></Button>
                </GridRows>
            ) : (
                    null
                )}

        </PresenterWrapper>
    );
};

export default Presenter;