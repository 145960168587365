import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.div`

`

const CheckboxInput = styled.input`
    margin-right: 10px;
`

const CheckboxLabel = styled.label`
    vertical-align: bottom;
    padding: 0;
    margin: 0;
    font-size: 12px;
`

const Checkbox = ({ label, name, value, checked, checkboxChangeHandler }) => {
    const [isChecked, setIsChecked] = useState(checked);

    const changeHandler = () => {
        checkboxChangeHandler(name, !isChecked);
        setIsChecked(!isChecked);
    }

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return (
        <CheckboxContainer>
            <CheckboxInput id={name} type="checkbox" value={value} name={name} checked={isChecked} onChange={() => changeHandler()}></CheckboxInput>
            <CheckboxLabel htmlFor={name}>{label}</CheckboxLabel>
        </CheckboxContainer>
    )
}

export default Checkbox
