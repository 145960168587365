import React from 'react'
import styled, { keyframes } from 'styled-components'

import * as constants from '../helpers/constants';

const RGB_HEX = /^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i;

const hex2RGB = str => {
  const [, short, long] = String(str).match(RGB_HEX) || [];

  if (long) {
    const value = Number.parseInt(long, 16);
    return [value >> 16, value >> 8 & 0xFF, value & 0xFF];
  } else if (short) {
    return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
  }
};

const [R, G, B] = hex2RGB(constants.COLOR_PRIMARY)

export const keyFrameAnimation = keyframes`
0%,
100% {
  box-shadow: 0em -2.6em 0em 0em ${constants.COLOR_PRIMARY}, 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.2), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.2), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.2), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.5), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.7);
}
12.5% {
  box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.7), 1.8em -1.8em 0 0em ${constants.COLOR_PRIMARY}, 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.2), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.2), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.2), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.5);
}
25% {
  box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.5), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.7), 2.5em 0em 0 0em ${constants.COLOR_PRIMARY}, 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.2), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.2), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2);
}
37.5% {
  box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.2), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.5), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.7), 1.75em 1.75em 0 0em ${constants.COLOR_PRIMARY}, 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.2), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2);
}
50% {
  box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.2), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.5), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.7), 0em 2.5em 0 0em ${constants.COLOR_PRIMARY}, -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.2), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2);
}
62.5% {
  box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.2), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.2), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.5), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.7), -1.8em 1.8em 0 0em ${constants.COLOR_PRIMARY}, -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2);
}
75% {
  box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.2), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.2), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.2), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.5), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.7), -2.6em 0em 0 0em ${constants.COLOR_PRIMARY}, -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2);
}
87.5% {
  box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.2), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.2), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.2), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.5), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.7), -1.8em -1.8em 0 0em ${constants.COLOR_PRIMARY};
}
`;

export const keyFrameAnimationWebKit = keyframes`
0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em ${constants.COLOR_PRIMARY}, 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.2), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.2), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.2), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.5), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.7), 1.8em -1.8em 0 0em ${constants.COLOR_PRIMARY}, 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.2), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.2), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.2), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.5), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.7), 2.5em 0em 0 0em ${constants.COLOR_PRIMARY}, 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.2), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.2), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.2), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.5), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.7), 1.75em 1.75em 0 0em ${constants.COLOR_PRIMARY}, 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.2), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.2), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.5), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.7), 0em 2.5em 0 0em ${constants.COLOR_PRIMARY}, -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.2), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.2), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.2), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.5), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.7), -1.8em 1.8em 0 0em ${constants.COLOR_PRIMARY}, -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.2), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.2), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.2), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.5), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.7), -2.6em 0em 0 0em ${constants.COLOR_PRIMARY}, -1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(${R},${G},${B}, 0.2), 1.8em -1.8em 0 0em rgba(${R},${G},${B}, 0.2), 2.5em 0em 0 0em rgba(${R},${G},${B}, 0.2), 1.75em 1.75em 0 0em rgba(${R},${G},${B}, 0.2), 0em 2.5em 0 0em rgba(${R},${G},${B}, 0.2), -1.8em 1.8em 0 0em rgba(${R},${G},${B}, 0.5), -2.6em 0em 0 0em rgba(${R},${G},${B}, 0.7), -1.8em -1.8em 0 0em ${constants.COLOR_PRIMARY};
  }
`;

const Loader = styled.div`
    margin: 100px auto;
    font-size: 25px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -${props => (props.loadingText ? props.loadingText.length * 0.25 : 0)}em;
    -webkit-animation: ${keyFrameAnimationWebKit} 1.1s infinite ease;
    animation: ${keyFrameAnimation} 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

    &::before {
        content: "${props => props.loadingText}";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) translateX(${props => (props.loadingText ? props.loadingText.length * 0.1 : 0)}em);
        font-weight: bold;
        color: ${constants.COLOR_PRIMARY};
    }
  
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

Loader.defaultProps = {
  loadingText: '',
};

const Spinner = ({loadingText}) => {
  return (
    <SpinnerContainer>
      <Loader loadingText={loadingText}/>
    </SpinnerContainer>
  );
}

export default Spinner