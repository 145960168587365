import React, { useState, useEffect, useContext } from "react";
import styled from 'styled-components';
import MapContext from '../../contexts/MapContext';
import AuthContext from "../../contexts/AuthContext";
import LoadingContext from "../../contexts/LoadingContext";

import * as C from '../../helpers/constants';
import Input from '../../ui/Input';
import Checkbox from '../../ui/Checkbox';
import Textarea from '../../ui/Textarea';
import Select from '../../ui/Select';
import Label from '../../ui/Label';
import Button from '../../ui/Button';
import * as Req from '../../helpers/requester'

const FormGrid = styled.form`
    display: grid;
    grid-template-rows: auto;
    grid-gap: 0.2rem;
    justify-content: start;
`;

const ButtonGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
    justify-content: start;
`;

const ImgContainer = styled.div`
    width: 100%
    height: 100px;
`;

const Img = styled.img`
    width: 90%; 
    height: 90%; 
    object-fit: contain;
`;

const MarkerEditor = () => {
    const mapContext = useContext(MapContext);
    const authContext = useContext(AuthContext);
    const loadingContext = useContext(LoadingContext);
    const [image, setImage] = useState(null);
    const [isValid, setIsValid] = useState(false);

    const changeHandler = (event) => {
        mapContext.setIsEditingActiveMapObjectHandler(true);
    }

    const uploadFileHandler = (event) => {
        mapContext.setIsEditingActiveMapObjectHandler(true);
        setImage(URL.createObjectURL(event.target.files[0]));

    }

    const checkboxChangeHandler = (name, isChecked) => {
        setIsValid(isChecked)
        mapContext.setIsEditingActiveMapObjectHandler(true);
    }


    useEffect(() => {
        setIsValid(mapContext.activeMapObject.marker.isValid);
    }, [mapContext.activeMapObject.marker])

    useEffect(() => {
        if (!mapContext.activeMapObject.marker.img || mapContext.activeMapObject.marker.img === "") {
            setImage(null);
            return;
        }
        Req.getImage(mapContext.activeMapObject.marker.img)
            .then(result => {
                const img = URL.createObjectURL(result.data);
                setImage(img);

            })
            .catch(error => {
                authContext.errorHandler(error.response)
            });
    }, [mapContext.activeMapObject.marker.img])


    const submitHandler = (event) => {
        event.preventDefault();
        const mapObject = mapContext.activeMapObject
        const markerForm = new FormData(event.target)
        markerForm.append('lat', mapObject.marker.lat)
        markerForm.append('lng', mapObject.marker.lng)
        markerForm.append('district', mapObject.marker.district)
        markerForm.append('date', mapObject.marker.date)
        markerForm.delete('isValid');
        markerForm.append('isValid', isValid)

        if (mapObject.marker._id === undefined) {
            loadingContext.setIsLoading(true)
            Req.postMapObject(markerForm, "marker")
                .then(result => {
                    mapObject.marker = result.data
                    mapContext.gMap.add(mapObject)
                    mapContext.clearMapSelection()
                    loadingContext.setIsLoading(false)
                })
                .catch(error => {
                    authContext.errorHandler(error.response)
                    mapContext.clearMapSelection()
                    loadingContext.setIsLoading(false)
                });
        } else { 
            markerForm.append('_id', mapObject.marker._id);
            loadingContext.setIsLoading(true)
            Req.updateMapObject(markerForm, "marker")
                .then(result => {
                    mapObject.marker = result.data;
                    mapContext.gMap.update(mapObject)
                    mapContext.clearMapSelection()
                    loadingContext.setIsLoading(false)
                })
                .catch(error => {
                    authContext.errorHandler(error.response)
                    mapContext.clearMapSelection()
                    loadingContext.setIsLoading(false)
                });
        }

    }

    const abortHandler = (event) => {
        event.preventDefault();
        mapContext.gMap.undo(mapContext.activeMapObject)
        mapContext.clearMapSelection()

    }

    const deleteHandler = (event) => {
        event.preventDefault();
        const mapObject = mapContext.activeMapObject
        loadingContext.setIsLoading(true)
        Req.deleteMapObject(mapObject.marker, "marker")
            .then(result => {
                mapContext.gMap.remove(mapObject)
                mapContext.clearMapSelection()
                loadingContext.setIsLoading(false)
            }).catch(error => {
                authContext.errorHandler(error.response)
                loadingContext.setIsLoading(false)
            });
    }

    return (
        <FormGrid onSubmit={(event) => submitHandler(event)}>
            <ImgContainer><Img src={image} alt={""} /></ImgContainer>

            <Input
                type={C.FILE} name={C.FILE}
                initialValue={""}
                changeHandler={(event) => uploadFileHandler(event)}
            />
            <Label label={C.LABEL.TYPE}></Label>
            <Select
                name={C.TYPE}
                options={C.MARKER_TYPE_OPTIONS}
                initValue={mapContext.activeMapObject.marker.type}
                changeHandler={(event) => changeHandler(event)}>
            </Select>
            <Label label={C.LABEL.ADDRESS}></Label>
            <Input
                type={C.TEXT}
                name={C.ADDRESS}
                initialValue={mapContext.activeMapObject.marker.address}
                changeHandler={(event) => changeHandler(event)}>

            </Input>
            <Label label={C.LABEL.MESSAGE}></Label>
            <Textarea
                type={C.TEXT}
                name={C.MESSAGE}
                rows={10}
                cols={30}
                initialValue={mapContext.activeMapObject.marker.message}
                changeHandler={(event) => changeHandler(event)}
                editable={true}>

            </Textarea>
            <Checkbox
                label={C.LABEL.APPROVED}
                name={"isValid"}
                checked={isValid}
                checkboxChangeHandler={(name, isChecked) => checkboxChangeHandler(name, isChecked)}>

            </Checkbox>
            <ButtonGrid>
                {mapContext.isEditingActiveMapObject ?
                    (
                        <React.Fragment>
                            <Button type={"submit"} label={C.LABEL.SAVE}></Button>
                            <Button type={"button"} label={C.LABEL.CANCEL} clickHandler={(event) => abortHandler(event)}></Button>
                        </React.Fragment>
                    ) : (
                        <Button type={"button"} label={C.LABEL.REMOVE} clickHandler={(event) => deleteHandler(event)}></Button>)
                }
            </ButtonGrid>
        </FormGrid >
    );
};

export default MarkerEditor;
