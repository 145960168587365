import React, { useContext, useState, useEffect } from "react";
import styled from 'styled-components';
import MapContext from '../../contexts/MapContext';
import AuthContext from '../../contexts/AuthContext';
import LoadingContext from "../../contexts/LoadingContext";

import * as Utils from '../../helpers/utils';
import Checkbox from '../../ui/Checkbox';
import Button from '../../ui/Button';
import Label from '../../ui/Label';

import * as Req from '../../helpers/requester'

const FormGrid = styled.form`
    display: grid;
    grid-template-rows: auto;
    grid-gap: 0.2rem;
    justify-content: start;
`;

const ButtonGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
    justify-content: start;
`;

const Error = styled.p`
    color: red;
`;


const MasterForm = () => {
    const mapContext = useContext(MapContext);
    const authContext = useContext(AuthContext);
    const loadingContext = useContext(LoadingContext);
    const [isActive, setIsActive] = useState(false);
    const [isError, setIsError] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [duration, setDuration] = useState('');


    const checkboxActiveChangeHandler = (name, isChecked) => {
        setIsActive(isChecked)
        mapContext.setIsEditingActiveMapObjectHandler(true);
    }

    const masterDurationChangeHandler = (event) => {
        setDuration(event.target.value)
        if (/^[0-5][0-9]:[0-5][0-9]:[0-5][0-9]$/.test(event.target.value)) {
            setIsError(false)
        } else {
            setIsError(true)
        }

        setDuration(event.target.value)
        mapContext.setIsEditingActiveMapObjectHandler(true);
    }

    const updateMapObject = (data) => {
        mapContext.activeMapObject.updatePathWithRouteData(data.route)
        mapContext.activeMapObject.addPathListeners()
        mapContext.activeMapObject[mapContext.activeMapObject.type] = data
    }


    useEffect(() => {
        setIsActive(mapContext.activeMapObject.master.isActive);
        setDuration(Utils.formatDuration(mapContext.activeMapObject.master.duration) || '00:00:00')
    }, [mapContext.activeMapObject.master])

    const submitHandler = (event) => {
        event.preventDefault();
        setIsSubmitting(true)
        if (isError) {
            return
        }
        const mapObject = mapContext.activeMapObject
        mapObject.master.duration = Utils.TimeStringToMs(duration);
        mapObject.master.isActive = isActive;
        mapObject.master.isValid = true;
        if (mapContext.activeMapObject[mapContext.activeMapObject.type]._id === undefined) {
            mapObject.master.route = mapContext.activeMapObject.getPath().getArray()
            loadingContext.setIsLoading(true)
            Req.postMapObject(mapObject.master, "master")
                .then(result => {
                    updateMapObject(result.data)
                    mapContext.gMap.add(mapObject)
                    mapContext.clearMapSelection()
                    loadingContext.setIsLoading(false)
                })
                .catch(error => {
                    authContext.errorHandler(error.response)
                    mapContext.clearMapSelection()
                    loadingContext.setIsLoading(false)
                });

        } else {
            mapObject.updatePathWithParts()
            mapObject.master.route = mapContext.activeMapObject.getPath().getArray()
            mapObject.master.routeChanges = mapObject.routeChanges
            loadingContext.setIsLoading(true)
            Req.updateMapObject(mapObject.master, "master")
                .then(result => {
                    updateMapObject(result.data)
                    mapContext.gMap.update(mapObject)
                    mapContext.clearMapSelection()
                    loadingContext.setIsLoading(false)
                })
                .catch(error => {
                    authContext.errorHandler(error.response)
                    mapContext.clearMapSelection()
                    loadingContext.setIsLoading(false)
                });
        }
    }


    const abortHandler = (event) => {
        event.preventDefault();
        mapContext.gMap.undo(mapContext.activeMapObject)
        mapContext.clearMapSelection()
    }

    const deleteWarning = (event) => {
        event.preventDefault();
        return window.confirm(`Är du säker på att du vill ta bort huvudrutten?`);
    }

    const deleteHandler = (event) => {
        event.preventDefault();
        const mapObject = mapContext.activeMapObject
        loadingContext.setIsLoading(true)
        Req.deleteMapObject(mapObject[mapContext.activeMapObject.type], "master")
            .then(result => {
                mapContext.gMap.remove(mapObject)
                mapContext.clearMapSelection()
                loadingContext.setIsLoading(false)
            }).catch(error => {
                authContext.errorHandler(error.response)
                loadingContext.setIsLoading(false)
            });
    }

    return (
        <FormGrid onSubmit={(event) => submitHandler(event)} noValidate>
            <Label label={"Tid"}></Label>
            <input
                type={"text"}
                name={"duration"}
                value={duration}
                onChange={masterDurationChangeHandler}
                regex={/^[0-5][0-9]:[0-5][0-9]:[0-5][0-9]$/}
            >

            </input>
            {isSubmitting && isError ? (<Error>{"Tiden måste skrivas i formatet HH:MM:SS"}</Error>) : null}
            <Checkbox label={"Aktiv"} name={"isActive"} key={"isActive"} checked={isActive} checkboxChangeHandler={(name, isChecked) => checkboxActiveChangeHandler(name, isChecked)}></Checkbox>
            <ButtonGrid>
                {mapContext.isEditingActiveMapObject ?
                    (
                        <React.Fragment>
                            <Button type={"submit"} label={"Spara"}></Button>
                            <Button type={"button"} label={"Avbryt"} clickHandler={(event) => abortHandler(event)}></Button>
                        </React.Fragment>
                    ) : (
                        <Button type={"button"} label={"Radera"} clickHandler={(event) => (deleteWarning(event)) && deleteHandler(event)}></Button>)
                }
            </ButtonGrid>

        </FormGrid>
    );
};

export default MasterForm;
