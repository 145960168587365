import React, { useState, useEffect, useContext } from "react";
import styled from 'styled-components';
import MapContext from '../../contexts/MapContext';
import AuthContext from "../../contexts/AuthContext";
import LoadingContext from "../../contexts/LoadingContext";

import * as C from '../../helpers/constants';
import Input from '../../ui/Input';
import Checkbox from '../../ui/Checkbox';
import Textarea from '../../ui/Textarea';
import Select from '../../ui/Select';
import Label from '../../ui/Label';
import Button from '../../ui/Button';
import * as Req from '../../helpers/requester'

const FormGrid = styled.form`
    display: grid;
    grid-template-rows: auto;
    grid-gap: 0.2rem;
    justify-content: start;
`;

const ButtonGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
    justify-content: start;
`;

const P = styled.p`
    padding: 0;
    margin: 0;
    font-size: 12px;
`;

const FlagEditor = () => {
    const mapContext = useContext(MapContext);
    const authContext = useContext(AuthContext);
    const loadingContext = useContext(LoadingContext);
    const [address, setAddress] = useState("")


    useEffect(() => {
        Req.googleAddress(mapContext.activeMapObject.getPosition().lat(), mapContext.activeMapObject.getPosition().lng())
            .then(result => {
                setAddress(result.data.formatted_address.replace(', Sverige', ''))
            }).catch(err => {
                setAddress("Okänd address")
            })

    }, [mapContext.activeMapObject.flag])

    const submitHandler = (event) => {
        event.preventDefault();
        const mapObject = mapContext.activeMapObject
        mapObject.flag.isLocked = true;

        loadingContext.setIsLoading(true)
        Req.addMasterFlag(mapObject.flag, "flag")
            .then(result => {
                mapContext.gMap.add(mapObject)
                mapContext.clearMapSelection()
                loadingContext.setIsLoading(false)
            })
            .catch(error => {
                authContext.errorHandler(error.response)
                mapContext.clearMapSelection()
                loadingContext.setIsLoading(false)
            });
    }

    const abortHandler = (event) => {
        event.preventDefault();
        mapContext.gMap.undo(mapContext.activeMapObject)
        mapContext.clearMapSelection()
    }

    const deleteWarning = (event) => {
        event.preventDefault();
        return window.confirm(`Är du säker på att du vill ta bort kontrollzonen?`);
    }

    const deleteHandler = (event) => {
        event.preventDefault();
        const mapObject = mapContext.activeMapObject
        loadingContext.setIsLoading(true)
        Req.deleteFlagMarker(mapObject.flag, "flag")
            .then(result => {
                mapContext.gMap.remove(mapObject)
                mapContext.clearMapSelection()
                loadingContext.setIsLoading(false)
            }).catch(error => {
                authContext.errorHandler(error.response)
                loadingContext.setIsLoading(false)
            });
    }

    return (
        <FormGrid onSubmit={(event) => submitHandler(event)}>
            <P>
                Kontrollzon: {address}
            </P>
            <ButtonGrid>
                {mapContext.isEditingActiveMapObject ?
                    (
                        <React.Fragment>
                            <Button type={"submit"} label={C.LABEL.SAVE}></Button>
                            <Button type={"button"} label={C.LABEL.CANCEL} clickHandler={(event) => abortHandler(event)}></Button>
                        </React.Fragment>
                    ) : (
                        <Button type={"button"} label={C.LABEL.REMOVE} clickHandler={(event) => (deleteWarning(event)) && deleteHandler(event)}></Button>)
                }
            </ButtonGrid>
        </FormGrid >
    );
};

export default FlagEditor;
