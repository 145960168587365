import * as C from './constants';
import axios from 'axios';

const getTokenHeader = () => {
    return { Authorization: `Bearer ${localStorage.getItem(C.TOKEN)}` }
}

const getHeaderByMapObjectType = (type) => {
    let headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem(C.TOKEN)}` }
    if (type === "marker") {
        headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${localStorage.getItem(C.TOKEN)}` }
    }
    return headers
}

export const login = async (username, password, isAdmin, office) => {
    const data = { username: username, password: password, isAdmin: isAdmin, office: office }
    return axios({
        url: C.API_LOGIN,
        method: "post",
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const getLogs = async () => {
    return axios({
        url: C.API_LOGS,
        method: "get",
        headers: getTokenHeader()
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const getOffices = async () => {
    return axios({
        url: C.API_OFFICES,
        method: "get"
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const getActions = async () => {
    return axios({
        url: C.API_ACTIONS,
        method: "get",
        headers: getTokenHeader()
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

//User
export const getUser = async () => {
    return axios({
        url: C.API_USER,
        method: "get",
        headers: getTokenHeader()
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const postUser = async (data) => {
    return axios({
        url: C.API_USER,
        method: "post",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const getDistricts = async (page) => {
    return axios({
        url: C.API_DISTRICTS,
        method: "get",
        headers: getTokenHeader(),
        params: page
    }).then(result => {
        return result;
    }).catch(error => {
        console.log("error", error)
        throw error;
    });
}

export const getDistrictsCount = async () => {
    return axios({
        url: C.API_DISTRICTS_COUNT,
        method: "get",
        headers: getTokenHeader()
    }).then(result => {
        return result;
    }).catch(error => {
        console.log("error", error)
        throw error;
    });
}

export const getUnhandledDistricts = async () => {
    return axios({
        url: C.API_UNHANDLED_DISTRICTS,
        method: "get",
        headers: getTokenHeader()
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const resetMean = async (district) => {
    const data = district;
    return axios({
        url: C.API_RESET_MEAN,
        method: "put",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const getImage = async (imagePath) => {
    let url = C.API_IMAGE + "?img=" + imagePath;
    return axios({
        url: url,
        method: "get",
        headers: getTokenHeader(),
        responseType: 'blob'
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const updateMaster = async (master) => {
    const data = master;
    return axios({
        url: C.API_MASTER,
        method: "put",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const deleteMaster = async (master) => {
    const data = master;
    return axios({
        url: C.API_MASTER,
        method: "delete",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const activateMaster = async (master) => {
    const data = {
        isActive: master.isActive,
        district: master.district,
        _id: master._id
    }
    return axios({
        url: C.API_ACTIVATE_MASTER,
        method: "put",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const activateAllMasters = async (masters) => {
    const requests = masters.map(data => {
        return axios({
            url: C.API_ACTIVATE_MASTER,
            method: "put",
            headers: getTokenHeader(),
            data: {
                _id: data._id,
                isValid: data.isValid,
                isActive: data.isActive,
                district: data.district
            }
        })

    })

    try {
        const responses = await axios.all(requests)
        return responses
    } catch (error) {
        throw error;
    }
}


export const deleteAllMasters = async (masters) => {
    const requests = masters.map(data => {
        return axios({
            url: C.API_MASTER,
            method: "delete",
            headers: getTokenHeader(),
            data: {
                _id: data._id,
            }
        })
    })

    try {
        const responses = await axios.all(requests)
        return responses
    } catch (error) {
        throw error;
    }
}


export const handleRoute = async (id) => {
    const data = { _id: id }
    return axios({
        url: C.API_ROUTE,
        method: "put",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const updateMarker = async (marker) => {
    const data = marker;
    return axios({
        url: C.API_MARKER,
        method: "put",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const updateAllMarkers = async (markers) => {
    const requests = markers.map(data => {
        return axios({
            url: C.API_MARKER,
            method: "put",
            headers: getTokenHeader(),
            data: {
                _id: data._id, 
                message: data.message, 
                isValid: true,
                type: data.type,
                district: data.district
            }
        })

    })

    try {
        const responses = await axios.all(requests)
        return responses
    } catch (error) {
        throw error;
    }
}


export const deleteAllMarkers = async (markers) => {
    const requests = markers.map(data => {
        return axios({
            url: C.API_MARKER,
            method: "delete",
            headers: getTokenHeader(),
            data: {
                _id: data._id,
            }
        })

    })

    try {
        const responses = await axios.all(requests)
        return responses
    } catch (error) {
        throw error;
    }
}

export const deleteMarker = async (marker) => {
    const data = marker;
    return axios({
        url: C.API_MARKER,
        method: "delete",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const deleteFlagMarker = async (flag) => {
    const data = flag;
    return axios({
        url: C.API_FLAG,
        method: "delete",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const addMasterFlag = async (flag) => {
    const data = flag;
    return axios({
        url: C.API_FLAG,
        method: "put",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const postMapObject = async (data, type) => {
    return axios({
        url: C.ADMIN_BASE + type,
        method: "post",
        headers: getHeaderByMapObjectType(type),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const updateMapObject = async (data, type) => {
    return axios({
        url: C.ADMIN_BASE + type,
        method: "put",
        headers: getHeaderByMapObjectType(type),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const deleteMapObject = async (data, type) => {
    return axios({
        url: C.ADMIN_BASE + type,
        method: "delete",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const googleAddress = async (lat, lng) => {
    const data = { lat: lat, lng: lng };
    return axios({
        url: C.API_GOOGLE_ADDRESS,
        method: "post",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

export const googleLocation = async (data) => {
    return axios({
        url: C.API_GOOGLE_LOCATION,
        method: "post",
        headers: getTokenHeader(),
        data: data
    }).then(result => {
        return result;
    }).catch(error => {
        throw error;
    });
}

