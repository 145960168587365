import * as C from '../helpers/constants';

const extensions = (googleMap, map, mapContext) => {

    const addRouteChanges = function (polyline, action, index) {
        var changeObject = {
            action: action,
            index: index,
        }
        if (polyline.type === "part") {
            changeObject.index = map.activeMapObject.polylineParts[1].getPath().getArray().length - 1 + index
        }

        if (action !== "remove") {
            changeObject.lat = polyline.getPath().getAt(index).lat()
            changeObject.lng = polyline.getPath().getAt(index).lng()
        }
        map.activeMapObject.routeChanges.push(changeObject)
    }

    googleMap.maps.Polyline.prototype.addPathListeners = function () {
        this.listeners.forEach((listener) => {
            googleMap.maps.event.removeListener(listener);
        })
        this.listeners = [];
        this.listeners.push(
            googleMap.maps.event.addListener(this, "rightclick", (event) => {
                if (event.hasOwnProperty("vertex")) {
                    this.getPath().removeAt(event.vertex)
                    mapContext.setIsEditingActiveMapObjectHandler(true)
                    map.isEditingActiveMapObject = true
                }
            }))

        this.listeners.push(
            googleMap.maps.event.addListener(this.getPath(), "set_at", (index, previous) => {
                addRouteChanges(this, "set", index)
                mapContext.setIsEditingActiveMapObjectHandler(true)
                map.isEditingActiveMapObject = true
            }))

        this.listeners.push(
            googleMap.maps.event.addListener(this.getPath(), "insert_at", (index) => {
                addRouteChanges(this, "insert", index)
                mapContext.setIsEditingActiveMapObjectHandler(true)
                map.isEditingActiveMapObject = true
            }))

        this.listeners.push(
            googleMap.maps.event.addListener(this.getPath(), "remove_at", (index, removed) => {
                addRouteChanges(this, "remove", index)
            }))
    }

    googleMap.maps.Polyline.prototype.updatePathWithParts = function () {
        if (this.polylineParts.length < 1) {
            return
        }
        this.polylineParts[1].getPath().getArray().pop()
        this.polylineParts[2].getPath().getArray().shift()
        const editedPath = [
            ...this.polylineParts[1].getPath().getArray(),
            ...this.polylineParts[0].getPath().getArray(),
            ...this.polylineParts[2].getPath().getArray()
        ]
        this.setPath(editedPath)
        this.clearPolylineParts()
        this.addPathListeners()
        for (const [index, point] of this.getPath().getArray().entries()) {
            if (point.bearing != undefined) {
                point.bearing = editedPath[index].bearing
            }
            point.time = editedPath[index].time
        }
        this.setVisible(map.filterMaster)
    }

    googleMap.maps.Polyline.prototype.updatePathWithRouteData = function (route) {
        for (const [index, point] of this.getPath().getArray().entries()) {
            if (route[index].bearing != undefined) {
                point.bearing = route[index].bearing
            }
            point.time = route[index].time
        }
    }

    googleMap.maps.Polyline.prototype.clearPolylineParts = function () {
        this.polylineParts.forEach(part => {
            part.endPoints.forEach(endPoint => endPoint.setMap(null));
            part.setMap(null)
        });
        this.polylineParts = []
    }

    googleMap.maps.Polyline.prototype.addPolylineParts = function (start, end) {
        const partsArray = this.splitIntoParts(start, end)
        for (const part of partsArray) {
            const polylinePart = map.shapes.polyline.part(part)
            this.polylineParts.push(polylinePart)
            for (const point of part.endPoints) {
                map.shapes.marker.endPoint(polylinePart, point)
            }
        }
    }

    googleMap.maps.Polyline.prototype.splitIntoParts = function (start, end) {
        const routeArray = this.getPath().getArray()
        const editPartPath = routeArray.slice(start, end)
        return [
            {
                path: editPartPath, //editable
                endPoints: [(start !== 0) && '0', (end !== routeArray.length) && `${editPartPath.length - 1}`],
                editable: true
            },
            {
                path: routeArray.slice(0, start + 1),  //start
                endPoints: [],
                editable: false
            },
            {
                path: routeArray.slice(end - 1, routeArray.length), //end
                endPoints: [],
                editable: false
            },
        ]
    }

    googleMap.maps.Polyline.prototype.undoPathChanges = function () {
        this.setPath(this[this.type].route)
        this.addPathListeners()
    }

    googleMap.maps.Polyline.prototype.addEndPoints = function () {
        this.clearEndPoints()
        map.shapes.marker.endPoint(this, "0")
        map.shapes.marker.endPoint(this, this.getPath().getArray().length - 1) // move
    }

    googleMap.maps.Polyline.prototype.clearEndPoints = function () {
        this.endPoints.forEach(endPoint => {
            endPoint.setMap(null)
        })
    }

    googleMap.maps.Polyline.prototype.addFlagpoints = function () {
        this.clearFlagpoints()
        this.master.flagpoints.forEach(flagpoint => {
            flagpoint.master = this._id
            flagpoint.district = this.district
            const flagObject = map.shapes.marker.masterFlagpoint(flagpoint, this);
            this.flagpoints.push(flagObject)
        })

    }

    googleMap.maps.Polyline.prototype.clearFlagpoints = function () {
        this.flagpoints.forEach(flagpoint => {
            flagpoint.setMap(null)
        })
    }

    googleMap.maps.Polyline.prototype.makeEditable = function (status) {
        this.setEditable(status)
        this.setOptions({ strokeColor: (status) ? C.COLOR_BLACK : C.MASTER_STROKE_COLOR })
        this.endPoints.forEach((endPoint) => {
            if (status) {
                endPoint.setVisible(false)
            } else {
                endPoint.setVisible(map.filterMaster)
            }
        })
    }

    googleMap.maps.Polyline.prototype.routeDuration = function () {
        const data = this[this.type]
        const duration = (new Date(data.route[data.route.length - 1].date)) - (new Date(data.route[0].date))
        let seconds = Math.floor((duration / 1000) % 60);
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds;

    }

    googleMap.maps.Polyline.prototype.closestVertex = function (latLng) {
        var distArr = [];
        var computeDistance = googleMap.maps.geometry.spherical.computeDistanceBetween;
        for (const [index, latLngPoint] of Object.entries(this.getPath().getArray())) {
            distArr.push({ index: index, distance: computeDistance(latLng, latLngPoint) });
        }
        return distArr.sort(function (a, b) {
            return a.distance - b.distance;
        })[0];
    }
}

export default extensions



