import React, { } from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";

import * as C from './helpers/constants';
import Header from "./components/header/Header";
import Home from './pages/Home'
import Map from './pages/Map'
import New from './pages/New'
import Users from './pages/Users'
import Logs from './pages/Logs'


const WrappingGrid = styled.div`
  display: grid;
  grid-template-rows: 60px 1fr 60px;
  min-height: 100vh;
`;

const HeaderGrid = styled.div`
  background-color: ${C.COLOR_SECONDARY};
`;

const Content = styled.div`
  background-color: ${C.COLOR_SECONDARY};
  display: grid;
  grid-template-columns: 1fr;
`;

const Main = styled.div`
  background-color: ${C.COLOR_SECONDARY};
  min-height: 80vh;
`;

const Footer = styled.div`
  background-color: ${C.COLOR_PRIMARY};
`;

const Grid = props => {
  return (
    <WrappingGrid>
      <HeaderGrid>
        <Header />
      </HeaderGrid>
      <Content>
        <Main>
          <Routes>
            <Route path="/logs" element={<Logs />} />
            <Route path="/map" element={<Map />} />
            <Route path="/new" element={<New />} />
            <Route path="/users" element={<Users />} />
            <Route path="/" exact element={<Home />} />
            <Route path="*" exact element={<Home />} />
          </Routes>
        </Main>
      </Content>

      <Footer></Footer>
    </WrappingGrid>
  );
};

export default Grid;
