//Authentication Roles
export const SUPER_ADMIN = "100";
export const ADMIN = "99";
export const SUPER_USER = "10";
export const USER = "1";

//Token Data
export const TOKEN = "token";
export const USER_ID = "userId";
export const USER_ROLE = "userRole";
export const USER_OFFICE = "userOffice";

//Version
export const VERSION = "0.7.1";

//Color
export const COLOR_BLUE = "#28AAE3";
export const COLOR_GREEN = "#28E37F";
export const COLOR_RED = "#E3284D";
export const COLOR_BLACK = "#000000";
export const COLOR_PRIMARY = "#1c6990";
export const COLOR_PRIMARY_LIGHT = "#6dc7f5";
export const COLOR_SECONDARY = "#FFFFFF";
export const COLOR_ASCENT = "#FFFFFF";

//Endpoints
export const ADMIN_BASE = process.env.REACT_APP_ADMIN_BASE;
console.log("ADMIN_BASE: ", ADMIN_BASE);
export const API_LOGIN = ADMIN_BASE + "login";
export const API_OFFICES = ADMIN_BASE + "offices";
export const API_AUTH = ADMIN_BASE + "auth";
export const API_USER = ADMIN_BASE + "user";
export const API_DISTRICTS = ADMIN_BASE + "districts";
export const API_DISTRICTS_COUNT = ADMIN_BASE + "districts/count";
export const API_MARKERS = ADMIN_BASE + "markers";
export const API_MARKER = ADMIN_BASE + "marker";
export const API_MASTERS = ADMIN_BASE + "masters";
export const API_MASTER = ADMIN_BASE + "master";
export const API_ROUTES = ADMIN_BASE + "routes";
export const API_ROUTE = ADMIN_BASE + "route";
export const API_IMAGE = ADMIN_BASE + "image";
export const API_GOOGLE_ADDRESS = ADMIN_BASE + "google/address";
export const API_GOOGLE_LOCATION = ADMIN_BASE + "google/location";
export const API_LOGS = ADMIN_BASE + "logs";
export const API_ACTIONS = ADMIN_BASE + "actions";
export const API_ACTIVATE_MASTER = ADMIN_BASE + "activateMaster";
export const API_UNHANDLED_DISTRICTS = ADMIN_BASE + "unhandledDistricts";
export const API_RESET_MEAN = ADMIN_BASE + "resetMean";
export const API_FLAG = ADMIN_BASE + "masterFlag";

//RouteValidation
export const MIN_VALID_DURATION_PERCENTAGE = 0.9;
export const MIN_VALID_CHECKPOINTS_PERCENTAGE = 0.85;
export const MIN_VALID_FLAGPOINTS_PERCENTAGE = 1.00;


//Map
export const MAX_EDITABLE_NODES = 101;
export const MAILBOX_MARKER = "MailboxMarker";
export const INFO_MARKER = "InfoMarker";

export const FILTER_DEGREES = 10;
export const FILTER_DISTANCE = 50;

export const IS_MODIFIED = 1;
export const NOT_MODIFIED = 0;


//Map Settings
export const MASTER_STROKE_COLOR = "#28AAE3FF"
export const MASTER_EDIT_STROKE_COLOR = "#000000FF"
export const MASTER_LINE_WIDTH = 6
export const MASTER_STROKE_OPACITY = 0.5

export const ROUTE_STROKE_COLOR = "#E3284DFF"
export const ROUTE_EDIT_STROKE_COLOR = "#000000FF"
export const ROUTE_LINE_WIDTH = 4
export const ROUTE_STROKE_OPACITY = 0.5

export const ROUTE_PROGRESS_STROKE_COLOR = "#28E37FFF"
export const ROUTE_PROGRESS_LINE_WIDTH = 8
export const ROUTE_PROGRESS_STROKE_OPACITY = 0.8

export const CHECKPOINT_TEXT_SIZE = 14
export const CHECKPOINT_FILL_COLOR = "#28AAE3FF"
export const CHECKPOINT_TEXT_COLOR = "#FFFFFFFF"

export const DISTRICT_LINE_WIDTH = 4
export const DISTRICT_TEXT_SIZE = 16
export const DISTRICT_STROKE_COLOR = "#E3284DFF"
export const DISTRICT_STROKE_OPACITY = 0.5
export const DISTRICT_TEXT_COLOR = "#E3284DFF"
export const DISTRICT_EDIT_STROKE_COLOR = "#000000FF"
export const DISTRICT_EDIT_FILL_COLOR = "#000000FF"
export const DISTRICT_EDIT_FILL_OPACITY = 0.3

//Map Filters
export const FILTER_CHECKBOXES = [
    { name: "marker", label: "Markeringar", isChecked: true },
    { name: "district", label: "Distrikt", isChecked: true },
    { name: "master", label: "Huvudrutt", isChecked: true },
    { name: "flag", label: "Kontrollzoner", isChecked: false },
    { name: "routeNew", label: "Leveransrutter nuvarande vecka", isChecked: true },
    { name: "routeOld", label: "Leveransrutter föregående vecka", isChecked: false },
]
export const FILTER_MARKERS = "Markeringar";
export const FILTER_DISTRICTS = "Distrikt";
export const FILTER_MASTER = "Huvudrutt";
export const FILTER_ROUTES = "Leveransrutt";

//forms
export const MARKER_TYPE_OPTIONS = [{ value: "mailbox", title: "Viktig Brevlåda" }, { value: "info", title: "Informationspunkt" }];
export const SEARCH_TYPE_OPTIONS = [{ value: "district", title: "Distrikt" }, { value: "address", title: "Adress" }, { value: "user", title: "Utdelare" }];
export const FILE = "file";
export const TYPE = "type";
export const ADDRESS = "address";
export const TEXT = "text";
export const MESSAGE = "message";
export const DISTRICT = "district";
export const BUTTON = "button";
export const LOGIN_FORM = {
    email: {
        elementType: "email",
        placeholder: "Användarnamn"
    },
    password: {
        elementType: "password",
        placeholder: "Lösenord"
    }
};


//Labels
export const LABEL = {
    ADD_MARKER: "Skapa punkt",
    ADD_DISTRICT: "Skapa district",
    ADD_ROUTE: "Skapa rutt",
    ADD_MASTER: "Skapa master",
    MODE_NORMAL: "Visningsläge",
    MODE_EDITOR: "Editor",
    ADDRESS: "Adress: ",
    MESSAGE: "Meddelande: ",
    TYPE: "Markeringstyp: ",
    EMPTY: "",
    SEARCH: "Sök",
    SAVE: "Spara",
    CANCEL: "Avbryt",
    REMOVE: "Radera",
    APPROVED: "Godkänd",
    LOCKED: "Låst"
}

export const SYMBOL = {
    TRIANGLE_UP: "M 11,3 1.5,19.5 20.5,19.5 Z",
    RECT: "M 0,0 22,0 22,22 0,22 Z"
}
