import React from "react";

const MapContext = React.createContext({
    isEditMode: false,
    activeMapObject: null,
    isEditingActiveMapObject: false,
    google: null,
    map: null,
    changeModeHandler: () => { },
    changeFilterHandler: () => { },
    setActiveMapObjectHandler: () => { },

});

export default MapContext;
