import React, { useState, useEffect, useContext } from "react";
import styled from 'styled-components'

import GoogleMap from '../components/GoogleMap';
import Top from '../components/Top';
import MapContext from "../contexts/MapContext";
import AuthContext from "../contexts/AuthContext";
import LoadingContext from "../contexts/LoadingContext";
import * as Req from '../helpers/requester';
import { useLoadDistricts } from '../hooks/LoadDataHook';
import MapSidebar from "../components/MapSidebar";
import MapHandler from "../components/MapHandler";
import Spinner from '../ui/Spinner'
import * as C from '../helpers/constants'

const WrappingGrid = styled.div`
display: grid;
grid-template-rows: 50px auto;

`;

const Content = styled.div`
display: grid;
height: calc(100vh - 170px);
grid-template-columns: auto 1fr auto;
`;

const Left = styled.div`
width: ${props => props.open ? '300px' : '0px'};
transition: all 0.5s;
`;


const LeftMenuButton = styled.div`
position: absolute;
top: calc((100% - 50px) / 2); 
left: ${props => props.open ? '275px' : '-15px'};
z-index: 99;
box-sizing: border-box;
background-color: #FFFFFF;
border: 1px solid ${C.COLOR_BLUE};
color: ${C.COLOR_BLUE};
text-decoration: none;
font-weight: normal;
font-size: 38px;
padding: 0rem 0.8rem 0.3rem 0.8rem;
border-radius: 50%;
text-align: right
cursor:pointer;
transition: 0.5s;

&:hover {
    color: white;
    background-color: ${C.COLOR_BLUE};
}
`;

const Right = styled.div`
width: ${props => props.open ? '800px' : '0px'};
transition: all 0.5s;
`;

const RightMenuButton = styled.div`
position: absolute;
top: calc((100% - 50px) / 2); 
right: ${props => props.open ? 'calc(800px - 25px)' : '-15px'};
z-index: 99;
box-sizing: border-box;
background-color: #FFFFFF;
border: 1px solid ${C.COLOR_BLUE};
color: ${C.COLOR_BLUE};
text-decoration: none;
font-weight: normal;
font-size: 38px;
padding: 0rem 0.8rem 0.3rem 0.8rem;
border-radius: 50%;
text-align: right
cursor:pointer;
transition: 0.5s;

&:hover {
    color: white;
    background-color: ${C.COLOR_BLUE};
}
`;

const Map = () => {
    const authContext = useContext(AuthContext);
    const loadingContext = useContext(LoadingContext)
    const [googleMap, setGoogleMap] = useState(null);
    const [gMap, setGMap] = useState(null);
    const [drawingManager, setDrawingManager] = useState(null);

    const [activeMapObject, setActiveMapObject] = useState(null);
    const [isEditingActiveMapObject, setIsEditingActiveMapObject] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)
    const [loading, loadingText, districtsData] = useLoadDistricts()
    const [leftIsOpen, setLeftIsOpen] = useState(true)
    const [rightIsOpen, setRightIsOpen] = useState(false)

    const initGoogleMapState = (google, map, manager) => {
        setGoogleMap(google)
        setGMap(map)
        setDrawingManager(manager)
    }

    useEffect(() => {
        if (isEditMode) {
            setRightIsOpen(!isEditMode)
        }

    }, [isEditMode])

    useEffect(() => {
        if (!districtsData | !googleMap | !gMap | !drawingManager) {
            return
        }
        updateMapWithData(districtsData)


    }, [districtsData, googleMap, gMap, drawingManager])

    const clearMapSelection = () => {
        if (gMap.activeMapObject === null) return;
        switch (gMap.activeMapObject.type) {
            case "district":
                gMap.activeMapObject.makeEditable(false)
                gMap.activeMapObject.setVisible(gMap.filterDistrict)
                gMap.activeMapObject.marker.setVisible(gMap.filterDistrict)
                break;
            case "master":
                gMap.activeMapObject.makeEditable(false)
                gMap.activeMapObject.setVisible(gMap.filterMaster)
                gMap.activeMapObject.flagpoints.forEach(flagpoint => {
                    flagpoint.setVisible(gMap.filterMasterFlagpoint)
                })
                gMap.activeMapObject.routeChanges = []

                break;
            case "marker":
                gMap.activeMapObject.updateIcon()
                gMap.activeMapObject.setVisible(gMap.filterMarker)
                break;
            case "flag":
                gMap.activeMapObject.updateIcon()
                gMap.activeMapObject.setVisible(gMap.filterMasterFlagpoint)
                break;
            default:
        }
        setIsEditingActiveMapObjectHandler(false)
        setActiveMapObjectHandler(null)

    }

    const setActiveMapObjectHandler = (mapObject) => {
        if (gMap !== null) {
            gMap.activeMapObject = mapObject
        }
        setActiveMapObject(mapObject)
    }

    const setIsEditingActiveMapObjectHandler = (status) => {
        if (gMap !== null) {
            gMap.isEditingActiveMapObject = status
            if (status === false && gMap.activeMapObject !== null && gMap.activeMapObject.type === "master") {
                gMap.activeMapObject.clearPolylineParts()
            }
        }
        setIsEditingActiveMapObject(status)
    }

    const setIsEditModeHandler = () => {
        setIsEditMode(!isEditMode);
        if (gMap !== null) {
            gMap.isEditMode = !isEditMode
            gMap.removeLocationMarkers()
            gMap.removeCheckpointMarkers()
            gMap.removeFlagpointMarkers()
            gMap.setActiveRoute(null)
        }
    }

    const startDrawingManager = (type) => {
        setIsEditingActiveMapObjectHandler(true);
        clearMapSelection()
        switch (type) {
            case "polygon":
                drawingManager.setDrawingMode(googleMap.maps.drawing.OverlayType.POLYGON);
                break;
            case "marker":
                drawingManager.setOptions({
                    markerOptions: markerOptions(type)
                })
                drawingManager.setDrawingMode(googleMap.maps.drawing.OverlayType.MARKER);
                break;
            case "flag":
                drawingManager.setOptions({
                    markerOptions: markerOptions(type)
                })
                drawingManager.setDrawingMode(googleMap.maps.drawing.OverlayType.MARKER);
                break;
            case "polyline":
                drawingManager.setDrawingMode(googleMap.maps.drawing.OverlayType.POLYLINE);
                break;
            default:
        }
    }


    const markerOptions = (type) => {
        if (type == "marker") {
            return {
                zIndex: 117,
                type: type,
                icon: {
                    path: googleMap.maps.SymbolPath.CIRCLE,
                    strokeColor: "#000000",
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: "#000000",
                    fillOpacity: 0.3,
                    radius: 5,
                    scale: 7,
                }
            }
        } else {
            return {
                zIndex: 117,
                type: type,
                icon: {
                    path: C.SYMBOL.TRIANGLE_UP,
                    strokeColor: "#000000",
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: "#000000",
                    fillOpacity: 0.2,
                    scale: 0.7,
                    anchor: new googleMap.maps.Point(11, 11)
                },
            }
        }

    }

    const changeModeHandler = () => {
        clearMapSelection()
        setIsEditModeHandler()
    }

    const mapSearchHandler = (filter, input) => {
        const data = { filter: filter, input: input }
        switch (filter) {
            case "address":
                Req.googleLocation(data)
                    .then(result => {
                        const location = result.data.geometry.location;
                        gMap.setCenter(location);
                        gMap.shapes.marker.location(location)
                    })
                    .catch(error => {
                        authContext.errorHandler(error.response)
                    });
                break;
            case "user":
                break;
            case "district":
                for (var district of gMap.districts) {
                    if (district.district.name === input) {
                        gMap.fitBounds(district.boundingBox(), googleMap);
                        break;
                    }
                }
                break;
            default:
        }
    }

    const updateMapWithData = (districtsData) => {
        gMap.removeAllOverlay()
        const bounds = new googleMap.maps.LatLngBounds()
        for (const district of districtsData) {
            const districtObj = gMap.shapes.polygon.district(district);

            const districtBounds = districtObj.boundingBox()
            bounds.union(districtBounds)

            for (const marker of district.markers) {
                const markerObj = gMap.shapes.marker.point(marker, districtObj);
                districtObj.mapMarkers.push(markerObj)
            }

            for (const route of district.routes) {
                const routeObj = gMap.shapes.polyline.route(route, districtObj);
                districtObj.mapRoutes.push(routeObj)
            }

            for (const master of district.masters) {
                const masterObj = gMap.shapes.polyline.master(master, districtObj);
                districtObj.mapMasters.push(masterObj)

                for (const flagpoint of master.flagpoints) {
                    flagpoint.master = master._id
                    flagpoint.district = district._id
                    const flagObject = gMap.shapes.marker.masterFlagpoint(flagpoint, masterObj);
                    masterObj.flagpoints.push(flagObject)
                }
            }

            gMap.masters.push(...districtObj.mapMasters)
            gMap.routes.push(...districtObj.mapRoutes)
            gMap.markers.push(...districtObj.mapMarkers)
            gMap.districts.push(districtObj)

        }
        gMap.fitBounds(bounds)
    }

    return (

        <MapContext.Provider
            value={{
                isEditMode: isEditMode,
                gMap: gMap,
                activeMapObject, activeMapObject,
                setActiveMapObjectHandler: setActiveMapObjectHandler,

                isEditingActiveMapObject: isEditingActiveMapObject,
                setIsEditingActiveMapObjectHandler: setIsEditingActiveMapObjectHandler,

                startDrawingManager: startDrawingManager,

                clearMapSelection: clearMapSelection,
            }}
        >
            {loading ? <Spinner loadingText={loadingText}></Spinner> : <WrappingGrid>
                <Top
                    changeModeHandler={() => changeModeHandler()}
                    mapSearchHandler={(filter, input) => mapSearchHandler(filter, input)} >
                </Top>
                <Content>
                    <Left open={leftIsOpen} ><MapSidebar></MapSidebar></Left>
                    <GoogleMap initState={(google, map, manager) => initGoogleMapState(google, map, manager)}></GoogleMap>
                    <Right open={rightIsOpen}>
                        <MapHandler districtsData={districtsData} mapSearchHandler={(filter, input) => mapSearchHandler(filter, input)}></MapHandler>
                    </Right>
                </Content>



                <LeftMenuButton open={leftIsOpen} onClick={() => setLeftIsOpen(!leftIsOpen)}>{leftIsOpen ? '<' : '>'}</LeftMenuButton>
                {(isEditMode) ?
                    null
                    :
                    <RightMenuButton open={rightIsOpen} onClick={() => setRightIsOpen(!rightIsOpen)}>{rightIsOpen ? '>' : '<'}</RightMenuButton>
                }
            </WrappingGrid>}
        </MapContext.Provider>
    );
}


export default Map;




