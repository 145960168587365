import React, { useRef, useContext, useState, useEffect } from "react";
import styled from "styled-components";

import { useLoadOffices } from '../hooks/LoadDataHook'
import Select from "../ui/Select";
import * as C from '../helpers/constants';
import AuthContext from '../contexts/AuthContext';
import * as Req from '../helpers/requester';

const StyledInput = styled.input`
  outline: none;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;

const Grid = styled.div`
  height: 100%;
  display: grid;

  align-items: center;
  justify-items: center;
`;

const WrappingGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 0.2rem;
  min-height: 100vh;
`;

const UserWrapper = styled.form`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 0.2rem;
`;

const StyledOption = styled.option``;

const StyledSelect = styled.select`
  font: inherit;
`;

const SubmitButton = styled.button`
float: right;
background-color: transparent;
border: solid 2px ${C.COLOR_PRIMARY};
border-radius: 7px;
color: ${C.COLOR_PRIMARY};
outline: none;
cursor: pointer;
font: inherit;
padding: 10px;
margin: 10px;
font-weight: bold;
&:hover {
  color: white;
  background-color: ${C.COLOR_PRIMARY};
}
`;

const Secure = () => {
  const authContext = useContext(AuthContext);
  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const rePasswordInputRef = useRef();
  const roleInputRef = useRef();
  const officeInputRef = useRef();
  const [offices, setOffices] = useState(null)
  const [officeLoad, officeData] = useLoadOffices(null)
  const [choosenOffice, setChoosenOffice] = useState(null)


  useEffect(() => {
    if (!officeData) {
      return
    }
    const officeObj = officeData.map(officeNumber => ({ value: officeNumber, title: officeNumber }))
    setOffices(officeObj)
    setChoosenOffice(officeObj[0].value)
  }, [officeData])

  const formElement = {
    firstName: {
      elementType: "text",
      placeholder: "Förnamn"
    },
    lastName: {
      elementType: "text",
      placeholder: "Efternamn"
    },
    email: {
      elementType: "email",
      placeholder: "Email"
    },
    role: {
      elementType: "select",
      placeholder: "Role",
      options: [
        {
          role: "Användare",
          value: C.USER
        },
        {
          role: "Super Användare",
          value: C.SUPER_USER
        },
        {
          role: "Admin",
          value: C.ADMIN
        },
        {
          role: "Super Admin",
          value: C.SUPER_ADMIN
        }
      ]
    },

  };

  const changeHandler = (event) => {
    setChoosenOffice(event.target.value)
  }

  const addUserHandler = (event) => {
    event.preventDefault();
    const user = {};
    user.firstName = firstNameInputRef.current.value;
    user.lastName = lastNameInputRef.current.value;
    user.email = emailInputRef.current.value;
    user.role = roleInputRef.current.value;
    user.office = parseInt(roleInputRef.current.value) < parseInt(C.ADMIN) ? choosenOffice : '*'
    Req.postUser(user)
      .then(response => {
        alert(`${response.data.email} är skapad med lösenord: ${response.data.password} (Spara lösenordet!)`)
      })
      .catch(error => {
        authContext.errorHandler(error.response)
      });
  };

  return (
    <WrappingGrid>
      <Grid>
        <UserWrapper onSubmit={(event) => addUserHandler(event)}>
          <StyledInput
            type={formElement.firstName.elementType}
            placeholder={formElement.firstName.placeholder}
            ref={firstNameInputRef}
          />{" "}
          <StyledInput
            type={formElement.lastName.elementType}
            placeholder={formElement.lastName.placeholder}
            ref={lastNameInputRef}
          />{" "}
          <StyledInput
            type={formElement.email.elementType}
            placeholder={formElement.email.placeholder}
            ref={emailInputRef}
          />{" "}
          <StyledSelect ref={roleInputRef}>
            {" "}
            {formElement.role.options.map((option, index) => {
               
              return (
                (parseInt(option.value) <= parseInt(authContext.role)) ? 
                  (
                  <StyledOption value={option.value} key={index} name={"role"}>
                    {" "}
                    {option.role}{" "}
                  </StyledOption>
                  ) : null
              );
            })}{" "}
          </StyledSelect>{" "}
          {(offices) ? (<Select name={"office"} options={offices} changeHandler={(event) => changeHandler(event)}></Select>) : null}
          
          <SubmitButton type={"submit"}> Skapa </SubmitButton>{" "}
        </UserWrapper>{" "}
      </Grid>{" "}
    </WrappingGrid>
  );
};

export default Secure;

