import iconImportant from '../img/info.png';
import iconMailbox from '../img/mailbox.png';
import * as C from '../helpers/constants'

const extensions = (googleMap, map, mapContext) => {

    googleMap.maps.Marker.prototype.updateIcon = function () {
        if (this.type === "marker") {
            this.setIcon({
                url: this.marker.type === "info" ? iconImportant : iconMailbox,
                scaledSize: new googleMap.maps.Size(15, 15),
                anchor: new googleMap.maps.Point(7.5, 7.5)
            });
        } else {
            this.setIcon({
                path: C.SYMBOL.TRIANGLE_UP,
                strokeColor: "#FF6600",
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillColor: "#FF6600",
                fillOpacity: 0.2,
                scale: 0.7,
                anchor: new googleMap.maps.Point(11, 11)
            });
        }
    }
}

export default extensions