import React, { useState, useContext } from "react";
import styled from 'styled-components';

import MapContext from '../contexts/MapContext'
import * as C from '../helpers/constants';
import Button from '../ui/Button';
import Select from '../ui/Select';
import Input from '../ui/Input';

const MapTopWrapper = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 100px;
grid-gap: 0.2rem;
`;

const MapSearchForm = styled.form`
display: grid;
grid-template-columns: 50px 100px 100px;
grid-gap: 0.1rem;
margin-left: 0.5rem;
justify-content: start;
`;

const MapDrawContainer = styled.div`
`;

const MapModeContainer = styled.div`
`;

const MapTop = ({ mapSearchHandler, changeModeHandler }) => {
    const mapContext = useContext(MapContext);
    const [filterOption, setFilterOption] = useState(C.SEARCH_TYPE_OPTIONS[0].value)
    const [input, setInput] = useState("")


    const searchHandler = (event) => {
        event.preventDefault();
        mapSearchHandler(filterOption, input)
    }

    const changeOptionsHandler = (event) => {
        setFilterOption(event.target.value)
    }

    const changeInputHandler = (event) => {
        setInput(event.target.value)
    }

    const onClickModeHandler = () => {
        changeModeHandler()
    }

    return (
        <MapTopWrapper>

            <MapSearchForm onSubmit={searchHandler}>
                <div>
                    <Button type={"submit"} label={"Sök"} disabled={mapContext.isEditingActiveMapObject}></Button>
                </div>

                <Select name={"select"} options={C.SEARCH_TYPE_OPTIONS} initValue={C.SEARCH_TYPE_OPTIONS[0].value} changeHandler={(event) => changeOptionsHandler(event)}></Select>
                <Input type={"text"} name={"input"} initialValue={""} changeHandler={(event) => changeInputHandler(event)} ></Input>
            </MapSearchForm>


            {mapContext.isEditMode ? (
                <MapDrawContainer>
                    <Button type={C.BUTTON} label={"Skapa Kontrollzon"} clickHandler={() => mapContext.startDrawingManager("flag")} disabled={mapContext.isEditingActiveMapObject}></Button>
                    <Button type={C.BUTTON} label={C.LABEL.ADD_MARKER} clickHandler={() => mapContext.startDrawingManager("marker")} disabled={mapContext.isEditingActiveMapObject}></Button>
                    <Button type={C.BUTTON} label={C.LABEL.ADD_ROUTE} clickHandler={() => mapContext.startDrawingManager("polyline")} disabled={mapContext.isEditingActiveMapObject}></Button>
                    <Button type={C.BUTTON} label={C.LABEL.ADD_DISTRICT} clickHandler={() => mapContext.startDrawingManager("polygon")} disabled={mapContext.isEditingActiveMapObject}></Button>
                </MapDrawContainer>
            ) : (
                    <MapDrawContainer>
                    </MapDrawContainer>
                )}


            <MapModeContainer>
                <button type={C.BUTTON} onClick={onClickModeHandler} disabled={mapContext.isEditingActiveMapObject}>{(mapContext.isEditMode) ? C.LABEL.MODE_NORMAL : C.LABEL.MODE_EDITOR}</button>
            </MapModeContainer>
        </MapTopWrapper>
    );
};

export default MapTop;