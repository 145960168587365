import React, { useEffect, useState, useContext } from 'react'
import Styled from 'styled-components'

import AuthContext from '../contexts/AuthContext'
import MapContext from '../contexts/MapContext'
import * as Req from '../helpers/requester'
import Spinner from '../ui/Spinner'
import Button from '../ui/StyledButton'
import * as Utils from '../helpers/utils'
import * as C from '../helpers/constants'

const Wrapper = Styled.div`
height: calc(100vh - 175px);
font-size: 14px;
`;

const Divider = Styled.div`
height: 20px;
font-size: 14px;
`;

const RoutesGridWrapper = Styled.div`
  margin: 0rem;
  padding: 0rem;
  overflow: auto;
  height: calc(100vh - 225px);
`;

const RouteGridWrapper = Styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(8, 1fr);
  margin: 0.5rem;
  padding: 0.5rem;
  grid-gap: 0.2rem;
  border: solid 1px ${C.COLOR_PRIMARY};
  font-size: 10px;
`;

const HeaderGridWrapper = Styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 0.2rem;
  margin: 0rem 0.5rem 0rem 0.5rem;
  padding: 0rem 0.5rem 0rem 0.5rem;
  font-size: 10px;
`;

const InformationWrapper = Styled.div`
  margin: 0.2rem;
  padding: 0.2rem;
  font-size: 14px;
`;

const P = Styled.p`
  color: ${props => props.isValid ? 'black' : 'red'};
`;

const I = Styled.p`
    margin: 0.0rem;
    padding: 0.0rem;
`;

const MapHandler = ({ districtsData, mapSearchHandler }) => {
    const authContext = useContext(AuthContext)
    const mapContext = useContext(MapContext)
    const [invalidRoutesData, setInvalidRoutesData] = useState(null)
    const [validRoutesData, setValidRoutesData] = useState(null)

    useEffect(() => {
        if (!districtsData) {
            return
        }

        filterDistrictsData(districtsData)

    }, [districtsData])

    const createRouteData = (district, route) => {
        const [durationString, validDuration] = validateDuration(route)
        const [checkpointString, validCheckpoints] = validateCheckpoints(route)
        const [flagpointString, validFlagpoints] = validateFlagpoints(route)
        const [dateString, validDate] = validateDate(route.date)
        const [accAvgString, validAccAvg] = validateAccAvg(route.accAvg)
        const [acceptedAccRatioString, validAcceptedAccRatio] = validateAcceptedAccRatio(route.acceptedAccRatio)

        return {
            district: {
                name: district.name,
                id: district._id
            },
            id: route._id,
            route: route.route,
            duration: {
                data: route.duration,
                string: durationString,
                isValid: validDuration
            },
            checkpoints: {
                data: route.checkpoints,
                string: checkpointString,
                isValid: validCheckpoints
            },
            flagpoints: {
                data: route.flagpoints,
                string: flagpointString,
                isValid: validFlagpoints
            },
            date: {
                data: route.date,
                string: dateString,
                isValid: validDate
            },
            date: {
                data: route.date,
                string: dateString,
                isValid: validDate
            },
            accAvg: {
                data: route.accAvg,
                string: accAvgString,
                isValid: validAccAvg
            },
            acceptedAccRatio: {
                data: route.acceptedAccRatio,
                string: acceptedAccRatioString,
                isValid: validAcceptedAccRatio
            },
        }
    }

    const routeWeekFilter = (route) => {
        const currentWeek = Utils.getWeekNumber(new Date())
        const bool = (
            Utils.getWeekNumber(route.date) == currentWeek - 1 || 
            Utils.getWeekNumber(route.date) == currentWeek
            )
        return bool
    }

    const routeValidFilter = (routeData) => {
        const bool = (!routeData.duration.isValid || !routeData.flagpoints.isValid || !routeData.checkpoints.isValid || !routeData.date.isValid)
        return bool
    }

    const filterDistrictsData = (districtsData) => {
        const invalidOfficeRoutes = []
        const validOfficeRoutes = []
        const officeRoutes = []
        districtsData.forEach(district => {
            let routes = district.routes.filter(routeWeekFilter)
                .map(route => {
                    return createRouteData(district, route)
                })
            officeRoutes.push(...routes)
        });
        officeRoutes.forEach(route => {
            if (routeValidFilter(route)) {
                invalidOfficeRoutes.push(route)
            } else {
                validOfficeRoutes.push(route)
            }
        })
        setInvalidRoutesData(invalidOfficeRoutes)
        setValidRoutesData(validOfficeRoutes)
    }

    const validateDuration = (route) => {
        return [Utils.formatDuration(route.duration), (route.duration / route.masterDuration) > C.MIN_VALID_DURATION_PERCENTAGE]

    }

    const validateCheckpoints = (route) => {
        const checkedCheckpoints = route.checkpoints.filter(c => c.isChecked == true).length
        return [
            checkedCheckpoints + "/" + route.checkpoints.length,
            route.checkpoints.length == 0 || (route.checkpoints.length > 0 && (checkedCheckpoints / route.checkpoints.length) >= C.MIN_VALID_CHECKPOINTS_PERCENTAGE)
        ]

    }

    const validateFlagpoints = (route) => {
        const checkedFlagpoints = route.flagpoints.filter(c => c.isChecked == true).length
        return [
            checkedFlagpoints + "/" + route.flagpoints.length,
            route.flagpoints.length == 0 || (route.flagpoints.length > 0 && (checkedFlagpoints / route.flagpoints.length) >= C.MIN_VALID_FLAGPOINTS_PERCENTAGE)
        ]

    }

    const validateDate = (date) => {
        if (date == undefined) {
            return [Utils.formattedIsoDateTime(date), false]
        } else {
            return [Utils.formattedIsoDateTime(date), Utils.validateRouteDate(date)]
        }

    }

    const validateAccAvg = (accAvg) => {
        if (accAvg == undefined) {
            return [`-`, true]
        }else {
            return [`~${accAvg} m`, true]
        }
    }

    const validateAcceptedAccRatio = (acceptedAccRatio) => {
        if (acceptedAccRatio == undefined) {
            return [`-`, true]
        } else {
            return [`${acceptedAccRatio}%`, true]
        }
    }

    const handleRouteHandler = (event, id) => {
        event.preventDefault();
        Req
            .handleRoute(id)
            .then(result => {
                const rIndex = invalidRoutesData.findIndex(route => route.id === id);
                //const newValidRoutes = 
                setValidRoutesData([...validRoutesData, invalidRoutesData[rIndex]]);
                setInvalidRoutesData(invalidRoutesData.filter((route, i) => i !== rIndex));
            })
            .catch(error => {
                authContext.errorHandler(error.response);
            });
    };

    const routeClickHandler = (event, route) => {
        mapContext.gMap.removeLocationMarkers()
        mapContext.gMap.removeCheckpointMarkers()
        mapContext.gMap.removeFlagpointMarkers()
        for (const flagpoint of route.flagpoints.data) {
            mapContext.gMap.shapes.marker.routeFlagpoint(flagpoint);
        }
        for (const checkpoint of route.checkpoints.data) {
            mapContext.gMap.shapes.marker.routeCheckpoint(checkpoint);
        }
        event.preventDefault();
        mapSearchHandler("district", route.district.name)
        mapContext.gMap.setActiveRoute(mapContext.gMap.routes.find(mapRoute => route.id == mapRoute.route._id))
    }

    return (
        <Wrapper>
            <InformationWrapper>
                <I>Slutförda: {invalidRoutesData != null && validRoutesData != null && invalidRoutesData.length + validRoutesData.length}</I>
                <I>Godkända: {validRoutesData != null && validRoutesData.length}</I>
                <I>Ogiltliga: {invalidRoutesData != null && invalidRoutesData.length}</I>
            </InformationWrapper>
            <RoutesGridWrapper>

                {invalidRoutesData != null && invalidRoutesData.length > 0 &&
                <HeaderGridWrapper>
                    <p></p>
                    <p>Skapad:</p>
                    <p>Tid:</p>
                    <p>Checkpunkter:</p>
                    <p>Kontrollzoner:</p>
                    <p>GPS snitt precision</p>
                    <p>GPS acceptans</p>
                    <p></p>
                </HeaderGridWrapper>
                }
                {invalidRoutesData != null && invalidRoutesData.map(route => {
                    return (
                        <RouteGridWrapper key={route.id}>
                            <Button
                                type={'click'}
                                label={route.district.name}
                                clickHandler={event => routeClickHandler(event, route)}
                            ></Button>
                            <P isValid={route.date.isValid}>{route.date.string}</P>
                            <P isValid={route.duration.isValid}>{route.duration.string}</P>
                            <P isValid={route.checkpoints.isValid}>{route.checkpoints.string}</P>
                            <P isValid={route.flagpoints.isValid}>{route.flagpoints.string}</P>
                            <P isValid={route.accAvg.isValid}>{route.accAvg.string}</P>
                            <P isValid={route.acceptedAccRatio.isValid}>{route.acceptedAccRatio.string}</P>
                            <Button
                                type={'click'}
                                label={'Hanterad'}
                                clickHandler={event => handleRouteHandler(event, route.id)}
                            ></Button>
                        </RouteGridWrapper>
                    );
                })}
            <Divider></Divider>
                {validRoutesData != null && validRoutesData.length > 0 &&
                    <HeaderGridWrapper>
                    <p></p>
                    <p>Skapad:</p>
                    <p>Tid:</p>
                    <p>Checkpunkter:</p>
                    <p>Kontrollzoner:</p>
                    <p></p>
                    </HeaderGridWrapper>
                }

                {validRoutesData != null && validRoutesData.map(route => {
                    return (
                        <RouteGridWrapper key={route.id}>
                            <Button
                                type={'click'}
                                label={route.district.name}
                                clickHandler={event => routeClickHandler(event, route)}
                            ></Button>
                            <P isValid={route.date.isValid}>{route.date.string}</P>
                            <P isValid={route.duration.isValid}>{route.duration.string}</P>
                            <P isValid={route.checkpoints.isValid}>{route.checkpoints.string}</P>
                            <P isValid={route.flagpoints.isValid}>{route.flagpoints.string}</P>
                            <P isValid={route.accAvg.isValid}>{route.accAvg.string}</P>
                            <P isValid={route.acceptedAccRatio.isValid}>{route.acceptedAccRatio.string}</P>
                        </RouteGridWrapper>
                    );
                })}
            </RoutesGridWrapper>
        </Wrapper >
    )
}

export default MapHandler