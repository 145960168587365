import React, { useState } from 'react'
import styled from 'styled-components'

const SelectContainer = styled.div`

`

const StyledSelect = styled.select`
    margin-right: 10px;
`

const Select = ({ name, options, initValue, changeHandler }) => {
    const [value, setValue] = useState(initValue);
    const internalChangeHandler = (event) => {
        setValue(event.target.value)
        changeHandler(event)
    }

    return (
        <SelectContainer>
            <StyledSelect value={value} name={name} onChange={(event) => internalChangeHandler(event)}>
                {options.map((option, index) => {
                    return (
                        <option value={option.value} key={index}>{option.title}</option>
                    )
                })}
            </StyledSelect>
        </SelectContainer>
    )
}

export default Select;
