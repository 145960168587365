import * as C from '../helpers/constants';

const extensions = (googleMap, map, mapContext) => {

    googleMap.maps.Polygon.prototype.updateActiveMasters = function (mapObject) {
        if (mapObject.master.isActive) {
            for (const master of this.mapMasters) {
                if (master.master._id !== mapObject.master._id) {
                    master.master.isActive = false;
                }
                master.setOptions({
                    strokeOpacity: master.master.isActive ? C.MASTER_STROKE_OPACITY : 0.2
                })
                master.clearEndPoints()
                master.addEndPoints()
                master.clearFlagpoints()
                master.addFlagpoints()
            }
        } else {
            mapObject.setOptions({
                strokeOpacity: mapObject.master.isActive ? C.MASTER_STROKE_OPACITY : 0.2
            })
            mapObject.clearEndPoints()
            mapObject.addEndPoints()
            mapObject.clearFlagpoints()
            mapObject.addFlagpoints()
        }
    }

    googleMap.maps.Polygon.prototype.undoLabelChanges = function () {
        this.marker.setPosition(this.district.frameCenterLocation)
        this.marker.setLabel({ ...this.marker.label, text: this.district.name })
    }

    googleMap.maps.Polygon.prototype.boundingBox = function () {
        var bounds = new googleMap.maps.LatLngBounds();
        this.getPath().forEach(function (element, index) {
            bounds.extend(element)
        });
        return (bounds);
    };

    googleMap.maps.Polygon.prototype.updateOptions = function (validMapZoom) {
        if (this.getEditable()) {
            this.setOptions({ fillColor: C.COLOR_BLACK, fillOpacity: 0.0, strokeColor: C.COLOR_BLACK })
        } else {
            if (!validMapZoom && this.district.masters.filter(master => (master.isActive == true)).length > 0) {
                this.setOptions({ fillColor: C.COLOR_BLUE, fillOpacity: 0.7, strokeColor: C.COLOR_BLUE })
            }
            if (validMapZoom) this.setOptions({ fillColor: C.COLOR_RED, fillOpacity: 0.0, strokeColor: C.COLOR_RED })
        }
    }

    googleMap.maps.Polygon.prototype.makeEditable = function (status) {
        this.setEditable(status)
        this.setOptions({ strokeColor: (status) ? C.COLOR_BLACK : C.DISTRICT_STROKE_COLOR })
    }

    googleMap.maps.Polygon.prototype.undoPathChanges = function () {
        this.setPath(this.district.frame)
        this.addPathListeners()
    }

    googleMap.maps.Polygon.prototype.addPathListeners = function () {
        this.listeners.forEach((listener) => {
            googleMap.maps.event.removeListener(listener);
        })
        this.listeners = [];
        googleMap.maps.event.addListener(this, "rightclick", (event) => {
            if (!this.getEditable()) {
                return
            }
            if (map.isEditMode && event.vertex === undefined) {
                if (googleMap.maps.geometry.poly.containsLocation(event.latLng, this)) {
                    this.marker.setPosition(event.latLng);
                    this.marker.setVisible(true);
                    mapContext.setIsEditingActiveMapObjectHandler(true)
                    map.isEditingActiveMapObject = true
                }
            } else if (map.isEditMode && event.hasOwnProperty("vertex")) {
                this.getPath().removeAt(event.vertex)
                mapContext.setIsEditingActiveMapObjectHandler(true)
                map.isEditingActiveMapObject = true
            }
        })

        googleMap.maps.event.addListener(this.getPath(), "set_at", (event) => {
            mapContext.setIsEditingActiveMapObjectHandler(true)
            map.isEditingActiveMapObject = true
        })

        googleMap.maps.event.addListener(this.getPath(), "insert_at", (event) => {
            mapContext.setIsEditingActiveMapObjectHandler(true)
            map.isEditingActiveMapObject = true
        })
    }
}

export default extensions