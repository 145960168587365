import React, { useContext, useEffect } from "react";
import styled from 'styled-components';


import Checkbox from '../ui/Checkbox'
import * as constants from '../helpers/constants';
import MapContext from '../contexts/MapContext';
import LoadingContext from '../contexts/LoadingContext';
import Presenter from './Presenter'
import DistrictForm from './forms/DistrictForm';
import MarkerForm from './forms/MarkerForm';
import MasterForm from './forms/MasterForm';
import FlagForm from './forms/FlagForm';
import RouteForm from './forms/RouteForm';
import Spinner from '../ui/Spinner'

const MapSidebarWrapper = styled.div`
display: grid;
grid-auto-rows: auto;
grid-gap: 1rem;
padding: 5px 5px 5px 5px;
background-color: ${constants.COLOR_SECONDARY};
`;



const MapFilterContainer = styled.div`
`;

const MapEditAndPresentWrapper = styled.div`
    height: calc(100vh - 375px);
`;

const MapSidebar = () => {
    const mapContext = useContext(MapContext);
    const loadingContext = useContext(LoadingContext);

    const checkboxChangeHandler = (name, isChecked) => {
        switch (name) {
            case "marker":
                mapContext.gMap.filterMarker = isChecked
                mapContext.gMap.updateVisibility("marker")
                break;
            case "flag":
                mapContext.gMap.filterMasterFlagpoint = isChecked
                mapContext.gMap.updateVisibility("flag")
                break;
            case "master":
                mapContext.gMap.filterMaster = isChecked
                mapContext.gMap.updateVisibility("master")
                break;
            case "routeNew":
                mapContext.gMap.filterRouteNew = isChecked
                mapContext.gMap.updateVisibility("route")
                break;
            case "routeOld":
                mapContext.gMap.filterRouteOld = isChecked
                mapContext.gMap.updateVisibility("route")
                break;
            case "district":
                mapContext.gMap.filterDistrict = isChecked
                mapContext.gMap.updateVisibility("district")
                break;
            default:
        }
    }
    
    return (
        <MapSidebarWrapper>
            <MapFilterContainer>
                {constants.FILTER_CHECKBOXES.map((checkbox, index) => {
                    return (
                        <Checkbox label={checkbox.label} name={checkbox.name} key={index} checked={checkbox.isChecked} checkboxChangeHandler={(name, isChecked) => checkboxChangeHandler(name, isChecked)}></Checkbox>
                    )
                })}

            </MapFilterContainer>
            {loadingContext.isLoading ? (<Spinner></Spinner>) : (
                <MapEditAndPresentWrapper>
                    {mapContext.isEditMode ?
                        mapContext.activeMapObject ?
                            <React.Fragment>
                                {mapContext.activeMapObject.type === "flag" ? <FlagForm /> : null}
                                {mapContext.activeMapObject.type === "master" ? <MasterForm /> : null}
                                {mapContext.activeMapObject.type === "marker" ? <MarkerForm /> : null}
                                {mapContext.activeMapObject.type === "district" ? <DistrictForm /> : null}
                            </React.Fragment> : null
                        :
                        mapContext.activeMapObject ? <Presenter></Presenter> : null
                    }
                </MapEditAndPresentWrapper>
            )}

        </MapSidebarWrapper>
    );
};

export default MapSidebar;