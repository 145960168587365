import React, { useRef, useEffect, useContext } from "react";
import styled from 'styled-components';
import jwtDecode from "jwt-decode";

import { useGoogleMap, useMap, useDrawingManager } from "../hooks/MapHook";
import * as Utils from '../helpers/utils'
import * as C from '../helpers/constants'
import iconImportant from '../img/info.png';
import iconMailbox from '../img/mailbox.png';
import iconStartBlue from '../img/startBlue.png';
import iconStopBlue from '../img/stopBlue.png';
import iconStartRed from '../img/startRed.png';
import iconStopRed from '../img/stopRed.png';
import MapContext from '../contexts/MapContext'
import AuthContext from '../contexts/AuthContext'
import * as Req from '../helpers/requester'
import Auth from "../pages/Auth";


const MapContainer = styled.div`
height: calc(100vh - 170px);
width: 100%;
`;

const initProperties = {
    isEditMode: false,
    activeMapObject: null,
    isEditingActiveMapObject: false,
    locationMarkers: [],
    checkpointMarkers: [],
    flagpointMarkers: [],
    activeRoute: null,
    districts: [],
    markers: [],
    masters: [],
    routes: [],
    shapes: {
        marker: {},
        polygon: {},
        polyline: {}
    },
    polylineStartDistrict: null,
    office: null,
    filterDistrict: true,
    filterMarker: true,
    filterMaster: true,
    filterMasterFlagpoint: false,
    filterRouteNew: true,
    filterRouteOld: false,
    drawingType: "marker"

}

const Map = (({ initState }) => {
    const authContext = useContext(AuthContext);
    const mapContext = useContext(MapContext);
    const googleMap = useGoogleMap();
    const mapContainerRef = useRef(null);
    const gMap = useMap(googleMap, mapContainerRef, initProperties);
    const drawingManager = useDrawingManager(googleMap, gMap)



    useEffect(() => {
        if (!googleMap || !gMap || !drawingManager) {
            return
        }
        addCustomMapShapes(googleMap, gMap, drawingManager)
        addGoogleMapListeners(googleMap, gMap, drawingManager)
        initState(googleMap, gMap, drawingManager)


    }, [googleMap, gMap, drawingManager])


    const addGoogleMapListeners = () => {
        googleMap.maps.event.addListener(drawingManager, "markercomplete", (mapObject) => {
            if (mapObject.type == "marker") {
                markerCompleteEventHandler(mapObject)
            } else {
                flagCompleteEventHandler(mapObject)
            }


        });

        //District
        googleMap.maps.event.addListener(drawingManager, "polygoncomplete", (mapObject) => {
            polygonCompleteEventHandler(mapObject)
        });

        //Route
        googleMap.maps.event.addListener(drawingManager, "polylinecomplete", (mapObject) => {
            polylineCompleteEventHandler(mapObject)
        });

        googleMap.maps.event.addListener(gMap, "click", (obj) => {
            if (longpress) {
                longpress = false
            }
            gMap.removeLocationMarkers()
            gMap.removeCheckpointMarkers()
            gMap.removeFlagpointMarkers()
            gMap.setActiveRoute(null)
            if (gMap.isEditMode && !gMap.isEditingActiveMapObject) {
                resetPreviousActiveMapObject()
                deselectActiveMapObject()
            }

        });

        googleMap.maps.event.addListener(gMap, "zoom_changed", (obj) => {
            gMap.updateVisibility("marker")
            gMap.updateVisibility("route")
            gMap.updateVisibility("district")
            gMap.updateVisibility("master")
            gMap.updateVisibility("flag")
        });

        var mousedUp = false;
        var longpress = false
        googleMap.maps.event.addListener(gMap, 'mousedown', function (event) {
            mousedUp = false;
            setTimeout(function () {
                if (mousedUp === false) {
                    longpress = true  
                }
            }, 2000);
        });
        googleMap.maps.event.addListener(gMap, 'mouseup', function (event) {
            mousedUp = true;
        });

        googleMap.maps.event.addListener(gMap, 'dragstart', function (event) {
            mousedUp = true;
        });

    }

    const addCustomMapShapes = () => {
        gMap.shapes.marker.location = (location) => {
            const locationMarker = new googleMap.maps.Marker({
                position: location,
                zIndex: 120,
                type: "place",
                map: gMap,
            })
            gMap.locationMarkers.push(locationMarker)
        }

        gMap.shapes.marker.routeFlagpoint = (flagpoint) => {
            const flagpointsMarker = new googleMap.maps.Marker({
                position: flagpoint,
                zIndex: 121,
                type: "flagpoint",
                icon: {
                    path: C.SYMBOL.TRIANGLE_UP,
                    strokeColor: C.COLOR_BLUE,
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: flagpoint.isChecked ? C.COLOR_GREEN : C.COLOR_RED,
                    fillOpacity: 0.8,
                    scale: 0.7,
                    anchor: new googleMap.maps.Point(11, 11)
                },
                isChecked: flagpoint.isChecked,
                map: gMap,
                visible: true
            })
            gMap.flagpointMarkers.push(flagpointsMarker)
        }

        gMap.shapes.marker.routeCheckpoint = (checkpoint) => {
            const checkpointMarker = new googleMap.maps.Marker({
                position: checkpoint,
                zIndex: 120,
                type: "checkpoint",
                icon: {
                    path: googleMap.maps.SymbolPath.CIRCLE,
                    strokeColor: C.COLOR_BLUE,
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: checkpoint.isChecked ? C.COLOR_GREEN : C.COLOR_RED,
                    fillOpacity: 0.8,
                    radius: 2,
                    scale: 4,
                    anchor: new googleMap.maps.Point(-0.1, -0.1)
                },
                isChecked: checkpoint.isChecked,
                map: gMap,
                visible: true
            })
            gMap.checkpointMarkers.push(checkpointMarker)
        }

        gMap.shapes.marker.masterFlagpoint = (flagpoint, mapMaster) => {
            const flagpointMarker = new googleMap.maps.Marker({
                position: flagpoint,
                zIndex: 121,
                type: "flag",
                icon: {
                    path: C.SYMBOL.TRIANGLE_UP,
                    strokeColor: "#FF6600",
                    strokeOpacity: mapMaster.master.isActive ? 0.8 : 0.2,
                    strokeWeight: 1,
                    fillColor: "#FF6600",
                    fillOpacity: mapMaster.master.isActive ? 0.2 : 0.0,
                    scale: 0.7,
                    anchor: new googleMap.maps.Point(11, 11)
                },
                map: gMap,
                flag: flagpoint,
                visible: mapMaster.master.isActive
            })
            addListeners(flagpointMarker)
            return flagpointMarker

        }

        gMap.shapes.marker.point = (markerData, district) => {
            const iconPath = markerData.type === "info" ? iconImportant : iconMailbox;
            const marker = new googleMap.maps.Marker({
                zIndex: 115,
                position: markerData,
                icon: {
                    url: iconPath,
                    scaledSize: new googleMap.maps.Size(15, 15),
                    anchor: new googleMap.maps.Point(7.5, 7.5)
                },
                type: "marker",
                marker: markerData,
                map: gMap,
                visible: true
            });
            addListeners(marker)
            return marker;
        }

        gMap.shapes.marker.endPoint = (polyline, pathPosition) => {
            const endPoint = new googleMap.maps.Marker({
                position: polyline.getPath().getArray()[pathPosition],
                map: gMap,
                zIndex: 116,
                draggable: false,
                connectedPolyline: polyline,
                type: "endPoint"
            })

            switch (polyline.type) {
                case "part":
                    endPoint.setIcon({
                        path: googleMap.maps.SymbolPath.CIRCLE,
                        strokeColor: C.COLOR_BLACK,
                        strokeOpacity: 0.8,
                        strokeWeight: 1,
                        fillColor: C.COLOR_BLACK,
                        fillOpacity: 0.8,
                        radius: 4,
                        scale: 8,
                        anchor: new googleMap.maps.Point(-0.1, -0.1)
                    })
                    polyline.endPoints.push(endPoint)
                    break;
                case "master":
                    endPoint.setIcon({
                        url: pathPosition === "0" ? iconStartBlue : iconStopBlue,
                        scaledSize: new googleMap.maps.Size(15, 15),
                        anchor: new googleMap.maps.Point(7.5, 7.5),

                    });
                    endPoint.setOptions({
                        opacity: polyline.master.isActive ? 0.8 : 0.2,
                    })
                    polyline.endPoints.push(endPoint)
                    addListeners(endPoint)
                    break;
                case "route":
                    endPoint.setIcon({
                        url: pathPosition === "0" ? iconStartRed : iconStopRed,
                        scaledSize: new googleMap.maps.Size(15, 15),
                        anchor: new googleMap.maps.Point(7.5, 7.5)
                    });
                    endPoint.setOptions({
                        opacity: 0.8,
                    })
                    polyline.endPoints.push(endPoint)
                    addListeners(endPoint)
                    break;
                default:
            }
            return endPoint

        }

        gMap.shapes.marker.label = (position, label, visible) => {
            return new googleMap.maps.Marker({
                position: position,
                zIndex: 115,
                type: "label",
                label: {
                    text: label,
                    color: C.DISTRICT_TEXT_COLOR,
                    fontSize: `${C.DISTRICT_TEXT_SIZE}px`,
                    fontWeight: "bold"
                },
                icon: {
                    path: googleMap.maps.SymbolPath.CIRCLE,
                    scale: 0
                },
                visible: visible,
                map: gMap,
            });
        }

        gMap.shapes.polyline.part = (part) => {
            const polyline = new googleMap.maps.Polyline({
                zIndex: 115,
                path: part.path,
                strokeColor: C.COLOR_BLACK,
                strokeOpacity: C.MASTER_STROKE_OPACITY,
                strokeWeight: C.MASTER_LINE_WIDTH,
                editable: part.editable,
                type: "part",
                map: gMap,
                visible: true,
                endPoints: [],
                listeners: [],
            });
            addListeners(polyline)
            return polyline
        }

        gMap.shapes.polyline.master = (master) => {
            const polyline = new googleMap.maps.Polyline({
                zIndex: 115,
                strokeColor: C.MASTER_STROKE_COLOR,
                strokeOpacity: master.isActive ? C.MASTER_STROKE_OPACITY : 0.2,
                strokeWeight: C.MASTER_LINE_WIDTH,
                editable: false,
                type: "master",
                master: master,
                path: master.route,
                map: gMap,
                visible: true,
                listeners: [],
                endPoints: [],
                polylineParts: [],
                routeChanges: [],
                flagpoints: []
            })
            polyline.updatePathWithRouteData(master.route)
            addListeners(polyline)
            polyline.addEndPoints()
            return polyline;
        }


        gMap.shapes.polyline.route = (route) => {
            const polyline = new googleMap.maps.Polyline({
                zIndex: 115,
                strokeColor: C.ROUTE_STROKE_COLOR,
                strokeOpacity: C.ROUTE_STROKE_OPACITY,
                strokeWeight: C.ROUTE_LINE_WIDTH,
                editable: false,
                type: "route",
                route: route,
                path: route.route,
                map: gMap,
                visible: true,
                listeners: [],
                endPoints: []
            });
            polyline.updatePathWithRouteData(route.route)
            addListeners(polyline)
            polyline.addEndPoints()
            return polyline
        }

        gMap.shapes.polygon.district = (district) => {
            const polygon = new googleMap.maps.Polygon({
                zIndex: 114,
                paths: district.frame,
                strokeColor: C.DISTRICT_STROKE_COLOR,
                strokeOpacity: C.DISTRICT_STROKE_OPACITY,
                strokeWeight: C.DISTRICT_LINE_WIDTH,
                fillColor: "#FF0000",
                fillOpacity: 0.0,
                editable: false,
                type: "district",
                district: district,
                mapMasters: [],
                mapRoutes: [],
                mapMarkers: [],
                map: gMap,
                visible: true,
                listeners: [],
            });

            addListeners(polygon)
            const districtLabel = gMap.shapes.marker.label(district.frameCenterLocation, district.name, true)
            polygon.marker = districtLabel
            return polygon
        }

    }

    const polylineCompleteEventHandler = (polyline) => {
        if (polyline.getPath().getArray().length < 3) {
            invalidMapObjectWarning(polyline, "En huvudrutt måsta bestå av minst tre punkter")
            return
        }
        if (!gMap.polylineStartDistrict) {
            polyline.setMap(null)
            drawingManager.setDrawingMode('polyline');
            return;
        }

        mapContext.setIsEditingActiveMapObjectHandler(true)
        polyline.master = {
            isActive: true,
            isValid: true,
            date: Utils.getDate(),
            route: polyline.getPath().getArray(),
            district: gMap.polylineStartDistrict.district._id,
            duration: 0
        }
        polyline.listeners = []
        polyline.polylineParts = []
        polyline.endPoints = []
        polyline.flagpoints = []
        gMap.polylineStartDistrict = null
        addListeners(polyline)

        gMap.activeMapObject = polyline
        mapContext.setActiveMapObjectHandler(polyline)

        drawingManager.setDrawingMode(null);

    }

    const invalidMapObjectWarning = (mapObject, message) => {
        window.confirm(message)
        mapContext.setIsEditingActiveMapObjectHandler(false)
        gMap.isEditingActiveMapObject = false
        gMap.activeMapObject = null
        mapContext.setActiveMapObjectHandler(null)
        drawingManager.setDrawingMode(null);
        mapObject.setMap(null)
    }

    const polygonCompleteEventHandler = (mapObject) => {
        if (mapObject.getPaths().getArray()[0].length < 3) {
            invalidMapObjectWarning(mapObject, "Ett distrikt måsta bestå av minst tre punkter")
            return
        }


        mapContext.setIsEditingActiveMapObjectHandler(true)
        gMap.isEditingActiveMapObject = true
        mapObject.district = {
            office: gMap.office,
            name: "",
            frame: [],
            frameCenterLocation: undefined,
            masters: [],
            markers: [],
            routes: [],

        }
        mapObject.mapMarkers = []
        mapObject.mapMasters = []
        mapObject.mapRoutes = []
        mapObject.listeners = []
        mapObject.marker = gMap.shapes.marker.label(mapObject.district.frameCenterLocation, "", false)
        gMap.activeMapObject = mapObject
        mapContext.setActiveMapObjectHandler(mapObject)
        addListeners(mapObject)
        drawingManager.setDrawingMode(null);
    }

    const flagCompleteEventHandler = (mapObject) => {
        let activeMaster = null;
        for (const district of gMap.districts) {
            if (googleMap.maps.geometry.poly.containsLocation(mapObject.getPosition(), district)) {
                activeMaster = district.district.masters.find(master => master.isActive)
                break;
            }

        }

        if (activeMaster != null) {
            mapObject.flag = {
                isLocked: true,
                district: activeMaster.district,
                master: activeMaster._id,
                time: 0,
                lat: mapObject.getPosition().lat(),
                lng: mapObject.getPosition().lng(),
                type: "flag"
            }
            addListeners(mapObject)
            mapContext.setIsEditingActiveMapObjectHandler(true)
            gMap.isEditingActiveMapObject = true

            gMap.activeMapObject = mapObject
            mapContext.setActiveMapObjectHandler(mapObject)

            drawingManager.setDrawingMode(null);
        } else {
            invalidMapObjectWarning(mapObject, "Hittar ingen aktiv huvudrutt att koppla kontrollzonen till, Se till att det finns en aktiv huvudrutt i distriktet först")
        }

    }

    const markerCompleteEventHandler = (mapObject) => {
        let insideDistrict = null;
        for (const district of gMap.districts) {
            if (googleMap.maps.geometry.poly.containsLocation(mapObject.getPosition(), district)) {
                insideDistrict = district
                break;
            }

        }

        if (insideDistrict != null) {
            Req.googleAddress(mapObject.getPosition().lat(), mapObject.getPosition().lng())
                .then(result => {
                    const address = result.data.formatted_address.replace(', Sverige', '')
                    mapObject.marker = {
                        address: address,
                        date: Utils.getDate(),
                        district: insideDistrict.district._id,
                        img: "",
                        isValid: true,
                        lat: mapObject.getPosition().lat(),
                        lng: mapObject.getPosition().lng(),
                        message: "",
                        type: "mailbox"
                    }
                    addListeners(mapObject)
                    mapContext.setIsEditingActiveMapObjectHandler(true)
                    gMap.isEditingActiveMapObject = true

                    gMap.activeMapObject = mapObject
                    mapContext.setActiveMapObjectHandler(mapObject)

                    drawingManager.setDrawingMode(null);


                })
                .catch(error => {
                    authContext.errorHandler(error.response)
                })
        } else {
            drawingManager.setDrawingMode(null);
            mapObject.setMap(null)
            drawingManager.setDrawingMode("marker")
        }
    }

    const addListeners = (mapObject) => {
        if (mapObject.type === "endPoint") {
            googleMap.maps.event.addListener(mapObject, "click", function (event) {
                if (gMap.isEditMode && mapObject.connectedPolyline.type === "route") {
                    return;
                }
                clickedMapObject(mapObject.connectedPolyline, { latLng: mapObject.getPosition() })
            })
            return
        }

        googleMap.maps.event.addListener(mapObject, "click", function (event) {
            clickedMapObject(mapObject, event)
        })

        if (mapObject.type === "district" || mapObject.type === "master" || mapObject.type === "district" || mapObject.type === "part") {
            mapObject.addPathListeners()
        }

    }

    const clickedMapObject = (mapObject, event) => {
        if (mapObject.type === "part" && !mapObject.getEditable()) {
            clickedPolylinePartHandler(mapObject, event)
            return;
        }
        if (gMap.isEditingActiveMapObject) {
            return;
        }

        if (!gMap.isEditMode) {
            mapContext.setActiveMapObjectHandler(mapObject)
            gMap.activeMapObject = mapObject
            return;
        }


        resetPreviousActiveMapObject()

        switch (mapObject.type) {
            case "marker":
                clickedMarkerHandler(mapObject, event)
                break;
            case "flag":
                clickedFlagHandler(mapObject, event)
                break;
            case "master":
                clickedRouteHandler(mapObject, event)
                break;
            case "route":
                break;
            case "district":
                clickedPolygonHandler(mapObject, event)
                break;
            case "endPoint":
                break;
            default:
        }

    }

    const resetPreviousActiveMapObject = () => {
        if (gMap.activeMapObject !== null) {
            switch (gMap.activeMapObject.type) {
                case "part":
                case "master":
                    gMap.activeMapObject.clearPolylineParts()
                    gMap.activeMapObject.setVisible(true)
                    gMap.activeMapObject.makeEditable(false)
                    break;
                case "marker":
                case "flag":
                    gMap.activeMapObject.updateIcon()
                    break;
                default:
                    gMap.activeMapObject.makeEditable(false)
                    break;
            }
            gMap.updateVisibility(gMap.activeMapObject.type)
        }

    }

    const deselectActiveMapObject = () => {
        if (gMap.activeMapObject !== null) {
            mapContext.setActiveMapObjectHandler(null)
            gMap.activeMapObject = null
        }
    }

    const clickedPolylinePartHandler = (polylinePart, e) => {
        gMap.activeMapObject.updatePathWithParts()
        if (gMap.activeMapObject.getPath().getArray().length < C.MAX_EDITABLE_NODES) {
            gMap.activeMapObject.makeEditable(true)
        } else {
            gMap.activeMapObject.setVisible(false)
            const closestVertex = gMap.activeMapObject.closestVertex(e.latLng)
            const [start, end] = Utils.spreadIndexRangeEqual(gMap.activeMapObject.getPath().getArray(), closestVertex, C.MAX_EDITABLE_NODES)
            gMap.activeMapObject.addPolylineParts(start, end)
        }
    }

    const clickedRouteHandler = (route, e) => {
        gMap.activeMapObject = route
        mapContext.setActiveMapObjectHandler(route)
        if (gMap.activeMapObject.getPath().getArray().length < C.MAX_EDITABLE_NODES) {
            gMap.activeMapObject.makeEditable(true)
        } else {
            gMap.activeMapObject.setVisible(false)
            gMap.activeMapObject.endPoints.forEach(endPoint => {
                endPoint.setVisible(false)
            })
            const closestVertex = gMap.activeMapObject.closestVertex(e.latLng)
            const [start, end] = Utils.spreadIndexRangeEqual(gMap.activeMapObject.getPath().getArray(), closestVertex, C.MAX_EDITABLE_NODES)
            gMap.activeMapObject.addPolylineParts(start, end)
        }
    }

    const clickedPolygonHandler = (polygon, e) => {
        polygon.makeEditable(true)
        mapContext.setActiveMapObjectHandler(polygon)
        gMap.activeMapObject = polygon
    }

    const clickedMarkerHandler = (marker, e) => {
        marker.setIcon({
            path: googleMap.maps.SymbolPath.CIRCLE,
            strokeColor: "#000000",
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: "#000000",
            fillOpacity: 0.3,
            radius: 5,
            scale: 7,
        });
        mapContext.setActiveMapObjectHandler(marker)
        gMap.activeMapObject = marker

    }

    const clickedFlagHandler = (marker, e) => {
        marker.setIcon({
            path: C.SYMBOL.TRIANGLE_UP,
            strokeOpacity: 0.8,
            strokeColor: "#000000",
            strokeWeight: 1,
            fillColor: "#000000",
            fillOpacity: 0.2,
            scale: 0.7,
            anchor: new googleMap.maps.Point(11, 11)
        });
        mapContext.setActiveMapObjectHandler(marker)
        gMap.activeMapObject = marker

    }

    const mapContainerClickListener = (event) => {
        if (drawingManager.getDrawingMode() === "polyline" && !gMap.polylineStartDistrict) {
            const offset = event.target.getClientRects()[0];
            const point = { x: event.clientX - offset.left, y: event.clientY - offset.top };
            const latLng = Utils.point2LatLng(point, googleMap, gMap);
            var pointIsInsideDistrict = false;
            for (const district of gMap.districts) {
                if (googleMap.maps.geometry.poly.containsLocation(latLng, district)) {
                    gMap.polylineStartDistrict = district
                    pointIsInsideDistrict = true;
                    break;
                }
            }
            if (!pointIsInsideDistrict) {
                drawingManager.setDrawingMode(null);
            }
        }
    }

    return (
        <MapContainer
            ref={mapContainerRef}
            onClick={(event) => mapContainerClickListener(event)}
        />
    );
});

export default Map;