import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { useLoadUnhandledDistricts } from '../hooks/LoadDataHook'
import * as C from '../helpers/constants';
import * as Req from '../helpers/requester';
import Textarea from '../ui/Textarea';
import Button from '../ui/StyledButton';
import tempImage from '../img/no-image.png';
import * as Utils from '../helpers/utils';
import AuthContext from '../contexts/AuthContext';

import Spinner from '../ui/Spinner';

const Img = styled.img`
  width: 150px;
`;

const NoData = styled.div`
  margin: 2rem;
  font-size: 24px;
  color: ${C.COLOR_PRIMARY};
  text-align: center;
`;

const MarkerGridWrapper = styled.form`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  margin: 1rem;
  padding: 1rem;
  grid-gap: 0.2rem;
  // border: solid 1px ${C.COLOR_PRIMARY};
  border: solid 1px ${props => props.isRemoved ? C.COLOR_RED : C.COLOR_PRIMARY};
  font-size: 12px;
`;


const MarkerActions = styled.div`
  justify-self: flex-end;
  align-self: center;
`;

const MasterGridWrapper = styled.form`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(5, 1fr);
  margin: 1rem;
  padding: 1rem;
  grid-gap: 0.2rem;
  border: solid 1px ${C.COLOR_PRIMARY};
  font-size: 12px;
`;


const HandleAllGridWrapper = styled.form`
  display: grid;
  grid-template-rows: 3fr;
  grid-template-columns: repeat(4, 1fr);
  margin: 1rem;
  padding: 1rem;
  grid-gap: 0.2rem;
  font-size: 12px;
  color: ${props => props.isRemoved ? C.COLOR_RED : C.COLOR_PRIMARY};
`;

const HandleAllMastersGridWrapper = styled.form`
  display: grid;
  grid-template-rows: 3fr;
  grid-template-columns: repeat(4, 1fr);
  margin: 1rem;
  padding: 1rem;
  grid-gap: 0.2rem;
  font-size: 12px;
  color: ${C.COLOR_PRIMARY} ;
`;

const MasterActions = styled.div`
  justify-self: flex-end;
  align-self: center;
`;

const New = props => {
  const authContext = useContext(AuthContext);
  const [masters, setMasters] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [images, setImages] = useState('');
  const [districtLoad, districtsData] = useLoadUnhandledDistricts(null)

  useEffect(() => {
    if (!districtsData) {
      return
    }
    const imagePromises = [];
    const invalidMarkersLoaded = [];
    const invalidMastersLoaded = [];


    for (const district of districtsData) {
      for (const marker of district.markers) {
        if (!marker.isValid) {
          marker.image = null;
          marker.districtName = district.name;
          invalidMarkersLoaded.push(marker);
          if (marker.img) {
            const promise = Req.getImage(marker.img);
            imagePromises.push(promise);
          }
        }
      }

      for (const master of district.masters) {
        if (!master.isValid) {
          master.districtName = district.name;
          invalidMastersLoaded.push({ ...master, districtName: district.name });
        }
      }
    }

    Promise.all(imagePromises)
      .then(values => {
        const imageListObj = {};
        for (const result of values) {
          const path = result.config.url.split('=').pop();
          const img = URL.createObjectURL(result.data);
          imageListObj[path] = img;
        }
        setImages(imageListObj);
      })
      .catch(error => {
        authContext.errorHandler(error.response);
      });
    setMasters(invalidMastersLoaded);
    setMarkers(invalidMarkersLoaded);
  }, [districtsData])


  const submitMasterHandler = (event, object) => {
    event.preventDefault();
    const data = masters.find(master => master._id === object._id);
    data.isValid = true;
    data.isActive = true;
    Req.activateMaster(data)
      .then(result => {
        setMasters(masters.filter(master => master._id !== object._id));
      })
      .catch(error => {
        authContext.errorHandler(error.response);
      });
  };

  const deleteMasterHandler = (event, object) => {
    event.preventDefault();
    Req
      .deleteMaster(object)
      .then(result => {
        setMasters(masters.filter(master => master._id !== object._id));
      })
      .catch(error => {
        authContext.errorHandler(error.response);
      });
  };

  const submitMarkerHandler = (event, object) => {
    event.preventDefault();
    const data = markers.find(marker => marker._id === object._id);
    data.isValid = true;
    if (data.isRemoved){
      data.isRemoved = false
    }
    Req
      .updateMarker(data)
      .then(result => {
        setMarkers(markers.filter(marker => marker._id !== object._id));
      })
      .catch(error => {
        authContext.errorHandler(error.response);
      });
  };


  const deleteMarkerHandler = (event, object) => {
    event.preventDefault();
    Req
      .deleteMarker(object)
      .then(result => {
        setMarkers(markers.filter(marker => marker._id !== object._id));
      })
      .catch(error => {
        authContext.errorHandler(error.response);
      });
  };

  const submitAllMarkersHandler = async (event, objects) => {
    event.preventDefault();
    try {
      const updatedObjects = [...objects].map( o => ({ ...o, isValid: true, isRemoved: false }));
      const responses = await Req.updateAllMarkers(updatedObjects)
      const markerIds = responses.map(r => r.data._id)
      setMarkers([...markers].filter(m => !markerIds.includes(m._id)));
    } catch (error) {
      console.log(error)
      authContext.errorHandler(error.response);
    }  
  };
  

  const deleteAllMarkersHandler = async (event, objects) => {
    event.preventDefault();
    try {
      const responses = await Req.deleteAllMarkers(objects)
      const markerIds = responses.map(r => r.data._id)
      setMarkers([...markers].filter(m => !markerIds.includes(m._id)));
    } catch (error) {
      console.log(error)
      authContext.errorHandler(error.response);
    }
  };

  const submitAllMastersHandler = async (event, objects) => {
    event.preventDefault();
    try {
      const updatedObjects = [...objects].map(o => ({ ...o, isValid: true, isActive: true }));
      const responses = await Req.activateAllMasters(updatedObjects)
      const masterIds = responses.map(r => r.data._id)
      setMasters([...masters].filter(m => !masterIds.includes(m._id)));
    } catch (error) {
      console.log(error)
      authContext.errorHandler(error.response);
    }


  };

  const deleteAllMastersHandler = async (event, objects) => {
    event.preventDefault();
    try {
      const responses = await Req.deleteAllMasters(objects)
      const masterIds = responses.map(r => r.data._id)
      setMasters([...masters].filter(m => !masterIds.includes(m._id)));
    } catch (error) {
      console.log(error)
      authContext.errorHandler(error.response);
    }
  };

  const markerWarning = (event, message) => {
    event.preventDefault();
    return window.confirm(message);
  }

  const masterWarning = (event, message) => {
    event.preventDefault();
    return window.confirm(message);
  }

  const getRemovedMarkers = (objects) => {
    return objects.filter(m => m.isRemoved)
  }

  const getUpdatedMarkers = (objects) => {
    return objects.filter(m => !m.isRemoved)
  }

  const handleAllGridRow = (objects, isRemovedMarkers) => {
    const title = (isRemovedMarkers) ? "Borttagna Markeringar" : "Nya/Ändrade mMrkeringar"
    const deleteWarningNeg = (isRemovedMarkers) ? "Är du säker på att du vill neka radering av alla borttagna markeringar" : "Är du säker på att du vill ta bort alla nya markeringar"
    const deleteWarningPos = (isRemovedMarkers) ? "Är du säker på att du vill godkänna radering av alla borttagna markeringar" : "Är du säker på att du vill godkänna alla nya markeringar"
    const buttonNeg = (isRemovedMarkers) ? "Neka Alla" : "Ta Bort Alla"
    const buttonPos = (isRemovedMarkers) ? "Godkänn Alla" : "Godkänn Alla"
    return (
      <React.Fragment>
        <p>{title}</p>
        <p>{objects.length}</p>
        <Button
          type={'button'}
          label={buttonNeg}
          clickHandler={event => (masterWarning(event, deleteWarningNeg)) && (isRemovedMarkers) ? submitAllMarkersHandler(event, objects) : deleteAllMarkersHandler(event, objects)}
          color={isRemovedMarkers ? C.COLOR_RED : C.COLOR_PRIMARY}
        ></Button>
        <Button
          type={'button'}
          label={buttonPos}
          clickHandler={event => (masterWarning(event, deleteWarningPos)) && (isRemovedMarkers) ? deleteAllMarkersHandler(event, objects) : submitAllMarkersHandler(event, objects)}
          color={isRemovedMarkers ? C.COLOR_RED : C.COLOR_PRIMARY}
        ></Button>
      </React.Fragment>
    )
  }

  const handleAllMastersGridRow = (objects) => {
    const title = "Nya Huvudrutter" 
    const deleteWarningNeg = "Är du säker på att du vill ta bort alla nya huvudrutter"
    const deleteWarningPos = "Är du säker på att du vill aktivera all nya huvudrutter"
    const buttonNeg = "Ta Bort Alla"
    const buttonPos = "Aktivera Alla"
    return (
      <React.Fragment>
        <p>{title}</p>
        <p>{objects.length}</p>
        <Button
          type={'button'}
          label={buttonNeg}
          clickHandler={event => (markerWarning(event, deleteWarningNeg)) && deleteAllMastersHandler(event, objects)}
          color={C.COLOR_PRIMARY}
        ></Button>
        <Button
          type={'button'}
          label={buttonPos}
          clickHandler={event => (markerWarning(event, deleteWarningPos)) && submitAllMastersHandler(event, objects)}
          color={C.COLOR_PRIMARY}
        ></Button>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {districtLoad ? (
        <Spinner></Spinner>
      ) : (
          masters.length === 0 && markers.length === 0 ? <NoData>Bra jobbat!<br />Inga ohanterade objekt.</NoData> :
            <React.Fragment>
              <HandleAllGridWrapper isRemoved={false}>
                {masters.length > 0 && handleAllMastersGridRow(masters)}
              </HandleAllGridWrapper>
              {masters.map(master => {
                return (
                  <MasterGridWrapper
                    key={master._id}
                    onSubmit={event => submitMasterHandler(event)}
                  >
                    <p><b>{master.districtName}</b></p>
                    <p>{Utils.formattedIsoDate(master.date)}</p>
                    <p>{Utils.formatDuration(master.duration)} h</p>
                    <p>{Utils.routeInKm(master.route)} km</p>
                    <MasterActions>
                      <Button
                        type={'submit'}
                        label={'Aktivera'}
                        clickHandler={event => submitMasterHandler(event, master)}
                        color={C.COLOR_PRIMARY}
                      ></Button>
                      <Button
                        type={'button'}
                        label={'Ta Bort'}
                        clickHandler={event => deleteMasterHandler(event, master)}
                        color={C.COLOR_PRIMARY}
                      ></Button>
                    </MasterActions>
                  </MasterGridWrapper>
                );
              })}


              <HandleAllGridWrapper isRemoved={false}>
                {getUpdatedMarkers(markers).length > 0 && handleAllGridRow(getUpdatedMarkers(markers), false)}
              </HandleAllGridWrapper>
              {markers.filter(m => m.isRemoved === undefined || m.isRemoved === false).map(marker => {
                return (
                  <MarkerGridWrapper
                    key={marker._id}
                    onSubmit={event => submitMarkerHandler(event, marker)}
                    isRemoved={marker.isRemoved}
                  >
                    <div>
                      <Img src={images[marker.img] ? images[marker.img] : tempImage} alt={''}></Img>
                    </div>
                    <div>
                      <p><b>{marker.districtName}</b></p>
                      <p>{Utils.formattedIsoDate(marker.date)}</p>
                      <p>{marker.address}</p>
                      <p>{marker.type === 'mailbox' ? 'Viktig brevlåda' : 'Informationspunkt'}</p>
                    </div>
                    <div>
                      <Textarea
                        type={'text'}
                        name={'message'}
                        rows={10}
                        cols={30}
                        initialValue={marker.message}
                        changeHandler={null}
                        editable={false}
                      ></Textarea>
                    </div>

                    <MarkerActions>
                      <Button
                        type={'submit'}
                        label={'Godkänn'}
                        clickHandler={event => submitMarkerHandler(event, marker)}
                      ></Button>
                      <Button
                        type={'button'}
                        label={'Ta Bort'}
                        clickHandler={event => deleteMarkerHandler(event, marker)}
                      ></Button>
                    </MarkerActions>
                  </MarkerGridWrapper>
                );
              })}

              <HandleAllGridWrapper isRemoved={true}>
                {getRemovedMarkers(markers).length > 0 && handleAllGridRow((getRemovedMarkers(markers)), true)}
              </HandleAllGridWrapper>
              {markers.filter(m => m.isRemoved !== undefined && m.isRemoved === true).map(marker => {
                return (
                  <MarkerGridWrapper
                    key={marker._id}
                    onSubmit={event => submitMarkerHandler(event, marker)}
                    isRemoved={marker.isRemoved}
                  >
                    <div>
                      <Img src={images[marker.img] ? images[marker.img] : tempImage} alt={''}></Img>
                    </div>
                    <div>
                      <p><b>{marker.districtName}</b></p>
                      <p>{Utils.formattedIsoDate(marker.date)}</p>
                      <p>{marker.address}</p>
                      <p>{marker.type === 'mailbox' ? 'Viktig brevlåda' : 'Informationspunkt'}</p>
                    </div>
                    <div>
                      <Textarea
                        type={'text'}
                        name={'message'}
                        rows={10}
                        cols={30}
                        initialValue={marker.message}
                        changeHandler={null}
                        editable={false}
                      ></Textarea>
                    </div>

                    <MarkerActions>
                      <Button
                        type={'button'}
                        label={'Godkänn'}
                        clickHandler={event => deleteMarkerHandler(event, marker)}
                        color={C.COLOR_RED}
                      ></Button>
                      <Button
                        type={'submit'}
                        label={'Neka'}
                        clickHandler={event => submitMarkerHandler(event, marker)}
                        color={C.COLOR_RED}
                      ></Button>
                    </MarkerActions>
                  </MarkerGridWrapper>
                );
              })}
            </React.Fragment>
        )}
    </React.Fragment>
  );
};

export default New;
