import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`

`

const Button = ({ type, label, clickHandler, disabled }) => {
    return (
        <StyledButton type={type} onClick={clickHandler} disabled={disabled}>{label}</StyledButton>
    )
}

export default Button;
