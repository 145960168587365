import React from 'react'
import styled from 'styled-components'
import * as C from '../helpers/constants'

const Button = styled.button`
float: right;
background-color: transparent;
border: solid 2px ${props => props.color ?? C.COLOR_PRIMARY};
border-radius: 7px;
color: ${props => props.color ?? C.COLOR_PRIMARY};
outline: none;
cursor: pointer;
font: inherit;
padding: 5px;
margin: 5px;
font-weight: bold;
&:hover {
  color: white;
  background-color: ${props => props.color ?? C.COLOR_PRIMARY};
}
`

const StyledButton = ({ type, label, clickHandler, disabled, color }) => {
  return (
    <Button type={type} onClick={clickHandler} disabled={disabled} color={color}>{label}</Button>
  )
}

export default StyledButton;