import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const InputContainer = styled.div`

`

const StyledTextarea = styled.textarea`
    margin-right: 10px;
`

const Textarea = ({ type, name, rows, cols, initialValue, changeHandler, editable }) => {
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const inputChangeHandler = (event) => {
        setValue(event.target.value);
        changeHandler(event);
    }

    return (
        <InputContainer>
            <StyledTextarea type={type} name={name} rows={rows} cols={cols} value={value} disabled={!editable} onChange={(event) => inputChangeHandler(event)} ></StyledTextarea>
        </InputContainer>
    )
}

export default Textarea;
