import React, { useEffect, useState, useContext } from 'react';

import * as Req from '../helpers/requester';
import AuthContext from '../contexts/AuthContext';

export const useLoadDistricts = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [err, setError] = useState(null);
    const authContext = useContext(AuthContext);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            let page = 1;
            let allDistricts = [];
            const result = await Req.getDistrictsCount();
            const total = result.data;
            while (true) {
                const result = await Req.getDistricts({ "page":page });
                const newDistricts = result.data;
                const percent = ((allDistricts.length + newDistricts.length) / (total.totalCount) * 100).toFixed(0);
                setLoadingText(percent + "%");
                if (newDistricts.length === 0){
                    break;
                }

                allDistricts = [...allDistricts, ...newDistricts];
                page++;
            }
            setData(allDistricts);
            setLoading(false);
            setLoadingText('')
        } catch (error) {
            console.log("Error:",error)
            setLoading(false);
            setLoadingText('error')
            setError(error);
            authContext.errorHandler(error.response);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    return [loading, loadingText, data, err];
};


export const useLoadOffices = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [err, setError] = useState(null);
    const authContext = useContext(AuthContext);
    useEffect(() => {
        setLoading(true)
        Req.getOffices()
            .then(result => {
                setData(result.data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                setError(error)
                authContext.errorHandler(error.response)
            })
    }, [])
    return [loading, data, err]
}

export const useLoadLogs = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [err, setError] = useState(null);
    const authContext = useContext(AuthContext);
    useEffect(() => {
        setLoading(true)
        Req.getLogs()
            .then(result => {
                setData(result.data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                setError(error)
                authContext.errorHandler(error.response)
            })
    }, [])
    return [loading, data, err]
}

export const useLoadActions = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [err, setError] = useState(null);
    const authContext = useContext(AuthContext);
    useEffect(() => {
        setLoading(true)
        Req.getActions()
            .then(result => {
                setData(result.data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                setError(error)
                authContext.errorHandler(error.response)
            })
    }, [])
    return [loading, data, err]
}

export const useLoadUnhandledDistricts = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [err, setError] = useState(null);
    const authContext = useContext(AuthContext);
    useEffect(() => {
        setLoading(true)
        Req.getUnhandledDistricts()
            .then(result => {
                setData(result.data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                setError(error)
                authContext.errorHandler(error.response)
            })
    }, [])
    return [loading, data, err]
}

