import React, { useContext } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import * as constants from "../../../helpers/constants";
import AuthContext from "../../../contexts/AuthContext";

const linksList = [
  { label: "Hem", url: "/", role: constants.USER },
  { label: "Karta", url: "/map", role: constants.USER },
  { label: "Nya Objekt", url: "/new", role: constants.USER },
  { label: "Användare", url: "/users", role: constants.ADMIN },
  { label: "Loggar", url: "/logs", role: constants.ADMIN }
];

const StyledNavigationList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  overflow: hidden;
  background-color: ${constants.COLOR_PRIMARY};
`;

const NavigationListitem = styled.li`
  display: inline-block;
`;

const NavigationListLink = styled(NavLink)`
  display: block;
  color: #eee;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &.active,
  &:active {
    color: black;
  }
`;
const clickHandler = props => {

};

const NavigationItems = props => {
  const authContext = useContext(AuthContext);
  return (
    <StyledNavigationList>
      {linksList
        .filter(link => {
          return parseInt(authContext.role) >= parseInt(link.role);
        })
        .map((link, index) => {
          return (
            <NavigationListitem key={index}>
              <NavigationListLink
                to={link.url}
                onClick={clickHandler}
                key={index}
                exact
              >
                {link.label}
              </NavigationListLink>
            </NavigationListitem>
          );
        })}
    </StyledNavigationList>
  );
};

export default NavigationItems;
