import React from 'react'
import styled from 'styled-components'

const StyledLabel = styled.label`
    padding: 0;
    margin: 0;
    font-size: 12px;
`

const Label = ({ label }) => {
    return (
        <StyledLabel>{label}
        </StyledLabel>
    )
}

export default Label;
