import * as C from '../helpers/constants';

const extensions = (googleMap, map, mapContext) => {
    googleMap.maps.Map.prototype.undo = function (mapObject) {
        switch (mapObject.type) {
            case "district":
                if (mapObject[mapObject.type]._id === undefined) {
                    mapObject.marker.setMap(null)
                    mapObject.setMap(null)
                } else {
                    mapObject.undoPathChanges()
                    mapObject.undoLabelChanges()
                }
                break;
            case "marker":
            case "flag":
                if (mapObject[mapObject.type]._id === undefined) {
                    mapObject.setMap(null)
                }
                mapObject.updateIcon()
                break;
            default:
                if (mapObject[mapObject.type]._id === undefined) {
                    mapObject.setMap(null)
                } else {
                    mapObject.undoPathChanges()
                }
        }
    }

    googleMap.maps.Map.prototype.remove = function (mapObject) {
        const mapDistrict = this.districts.find(d => d.district._id === mapObject[mapObject.type].district)
        switch (mapObject.type) {
            case "district":
                for (const mapMarker of mapObject.mapMarkers) {
                    this.markers = this.markers.filter((m) => m.marker._id !== mapMarker.marker._id)
                    mapMarker.setMap(null);
                }
                for (const mapMaster of mapObject.mapMasters) {
                    this.masters = this.masters.filter((m) => m.master._id !== mapMaster.master._id)
                    mapMaster.clearEndPoints()
                    mapMaster.clearFlagpoints()
                    mapMaster.setMap(null);
                }
                for (const mapRoute of mapObject.mapRoutes) {
                    this.routes = this.routes.filter((r) => r.route._id !== mapRoute.route._id)
                    mapRoute.endPoints.forEach(endPoint => {
                        endPoint.setMap(null)
                    })
                    mapRoute.setMap(null);
                }

                this.districts = this.districts.filter((d) => d.district._id !== mapObject.district._id)
                mapObject.marker.setMap(null)
                break;
            case "marker":
                this.markers = this.markers.filter((m) => m.marker._id !== mapObject.marker._id)
                mapDistrict.mapMarkers = mapDistrict.mapMarkers.filter((m) => m.marker._id !== mapObject.marker._id)
                mapDistrict.district.markers = mapDistrict.district.markers.filter((m) => m._id !== mapObject.marker._id)
                break;
            case "master":
                this.masters = this.masters.filter((m) => m.master._id !== mapObject.master._id)
                mapDistrict.mapMasters = mapDistrict.mapMasters.filter((m) => m.master._id !== mapObject.master._id)
                mapDistrict.district.masters = mapDistrict.district.masters.filter((m) => m._id !== mapObject.master._id)
                mapObject.clearEndPoints()
                mapObject.clearFlagpoints()
                break;
            case "route":
                this.routes = this.routes.filter((r) => r.route._id !== mapObject.route._id)
                mapDistrict.mapRoutes = mapDistrict.mapRoutes.filter((r) => r.route._id !== mapObject.route._id)
                mapDistrict.district.routes = mapDistrict.district.routes.filter((r) => r._id !== mapObject.route._id)
                mapObject.clearEndPoints()
                break;
            case "flag":
                const flagMaster = this.masters.find((m) => m.master._id === mapObject.flag.master)
                flagMaster.master.flagpoints = flagMaster.master.flagpoints.filter(f => (f !== mapObject.flag))
                flagMaster.flagpoints = flagMaster.flagpoints.filter(f => (f !== mapObject))
                break;
            default:
        }

        mapObject.setMap(null)
    }

    googleMap.maps.Map.prototype.add = function (mapObject) {
        const mapDistrict = this.districts.find(d => d.district._id === mapObject[mapObject.type].district)
        switch (mapObject.type) {
            case "district":
                this.districts.push(mapObject)
                mapObject.setOptions({ fillOpacity: 0.0 })
                break;
            case "marker":
                this.markers.push(mapObject)
                mapDistrict.mapMarkers.push(mapObject)
                mapDistrict.district.markers.push(mapObject.marker)
                mapObject.updateIcon()
                break;
            case "flag":
                const flagMaster = this.masters.find((m) => m.master._id === mapObject.flag.master)
                flagMaster.flagpoints.push(mapObject)
                flagMaster.master.flagpoints.push(mapObject.flag)
                mapObject.updateIcon()
                break;
            case "master":
                this.masters.push(mapObject)
                mapDistrict.mapMasters.push(mapObject)
                mapDistrict.district.masters.push(mapObject.master)
                mapDistrict.updateActiveMasters(mapObject)
                mapObject.addEndPoints()
                mapObject.addFlagpoints()
                break;
            default:
        }
    }

    googleMap.maps.Map.prototype.update = function (mapObject) {
        const mapDistrict = this.districts.find(d => d.district._id === mapObject[mapObject.type].district)
        switch (mapObject.type) {
            case "district":
                break;
            case "marker":
                mapObject.updateIcon()
                const markerIndex = mapDistrict.district.markers.findIndex(m => m._id === mapObject.marker._id)
                mapDistrict.district.markers[markerIndex] = mapObject.marker
                break;
            case "master":
                const masterIndex = mapDistrict.district.masters.findIndex(m => m._id === mapObject.master._id)
                mapDistrict.district.masters[masterIndex] = mapObject.master
                mapDistrict.updateActiveMasters(mapObject)
                mapObject.addEndPoints()
                break;
            default:
        }
    }

    googleMap.maps.Map.prototype.removeLocationMarkers = function () {
        this.locationMarkers.forEach(marker => {
            marker.setMap(null)
        });
        this.locationMarkers = []
    }

    googleMap.maps.Map.prototype.removeCheckpointMarkers = function () {
        this.checkpointMarkers.forEach(marker => {
            marker.setMap(null)
        });
        this.checkpointMarkers = []
    }

    googleMap.maps.Map.prototype.removeFlagpointMarkers = function () {
        this.flagpointMarkers.forEach(marker => {
            marker.setMap(null)
        });
        this.flagpointMarkers = []
    }

    googleMap.maps.Map.prototype.removeAnnotationMarkers = function () {
        this.markers.forEach(marker => {
            marker.setMap(null)
        });
        this.markers = []
    }

    googleMap.maps.Map.prototype.removeMasterPolylines = function () {
        this.masters.forEach(polyline => {
            polyline.setMap(null)
        });
        this.masters = []
    }

    googleMap.maps.Map.prototype.removeRoutePolylines = function () {
        this.routes.forEach(polyline => {
            polyline.setMap(null)
        });
        this.routes = []
    }

    googleMap.maps.Map.prototype.removeDistrictPolygon = function () {
        this.districts.forEach(polygon => {
            polygon.setMap(null)
        });
        this.districts = []
    }

    googleMap.maps.Map.prototype.setActiveRoute = function (route) {
        if (this.activeRoute !== null) {
            this.activeRoute.setOptions({ strokeColor: C.ROUTE_STROKE_COLOR })
            this.activeRoute = null;
        }
        if (route !== null) {
            this.activeRoute = route;
            this.activeRoute.setOptions({ strokeColor: C.ROUTE_EDIT_STROKE_COLOR })
        }
    }

    googleMap.maps.Map.prototype.removeAllOverlay = function () {
        this.removeAnnotationMarkers()
        this.removeCheckpointMarkers()
        this.removeFlagpointMarkers()
        this.removeLocationMarkers()
        this.removeMasterPolylines()
        this.removeRoutePolylines()
        this.removeDistrictPolygon()
        this.setActiveRoute(null)
    }

    googleMap.maps.Map.prototype.updateVisibility = function (type) {
        const showRoutes = this.getZoom() > 14
        const showMasters = this.getZoom() > 12
        const showMarkers = this.getZoom() > 14
        const showMasterFlagpoints = this.getZoom() > 14
        const showDistrictLabels = this.getZoom() > 12
        const showDistricts = this.getZoom() > 1
        switch (type) {
            case "district":
                this.districts.forEach(district => {
                    if (district.getEditable()) return;

                    district.marker.setVisible(showDistrictLabels && this.filterDistrict)
                    district.setVisible(showDistricts && this.filterDistrict)
                    district.updateOptions(showMasters)
                })
                break;
            case "marker":
                this.markers.forEach(marker => {
                    if (marker.icon.hasOwnProperty("path")) return
                    marker.setVisible(showMarkers && this.filterMarker)
                })
                break;
            case "route":
                this.routes.forEach(route => {
                    if (route.getEditable()) return;
                    var filter = isLessThanWeekOld(route) ? this.filterRouteNew : this.filterRouteOld
                    route.setVisible(filter && showRoutes)
                    route.endPoints.forEach(endPoint => {
                        endPoint.setVisible(filter && showRoutes)
                    })
                })
                break;
            case "master":
                this.masters.forEach(master => {
                    if (master.getEditable() || master.polylineParts.length > 0) return;
                    master.setVisible(showMasters && this.filterMaster)
                    master.endPoints.forEach(endPoint => {
                        endPoint.setVisible(showMasters && this.filterMaster)
                    })
                })
                break;
            case "flag":
                this.masters.forEach(master => {
                    master.flagpoints.forEach(flagpoint => {
                        flagpoint.setVisible(showMasterFlagpoints && this.filterMasterFlagpoint && master.master.isActive)


                    })
                })
                break;
            default:
        }
    }

    const isLessThanWeekOld = function (route) {
        const routeDate = Date.parse(route.route.date)
        const currentDate = Date.parse(new Date().toISOString())
        const weekInMilliseconds = 604800000
        return (currentDate - routeDate) < weekInMilliseconds
    }
}

export default extensions