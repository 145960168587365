import React, { useContext, useState, useEffect } from "react";
import styled from 'styled-components';
import MapContext from '../../contexts/MapContext';
import AuthContext from '../../contexts/AuthContext';
import LoadingContext from "../../contexts/LoadingContext";
import Button from '../../ui/Button';
import * as Utils from '../../helpers/utils';


import * as Req from '../../helpers/requester'

const Wrapper = styled.div`
height: calc(100vh - 175px);
overflow: auto;
font-size: 14px;
`;


const RouteForm = (props) => {
    const mapContext = useContext(MapContext);
    const loadingContext = useContext(LoadingContext);
    const authContext = useContext(AuthContext);


    const deleteHandler = (event) => {
        event.preventDefault();
        const mapObject = mapContext.activeMapObject
        loadingContext.setIsLoading(true)
        Req.deleteMapObject(mapObject[mapContext.activeMapObject.type], "route")
            .then(result => {
                mapContext.gMap.remove(mapObject)
                mapContext.clearMapSelection()
                loadingContext.setIsLoading(false)
            }).catch(error => {
                authContext.errorHandler(error.response)
                loadingContext.setIsLoading(false)
            });
    }

    return (
        <Wrapper>
            <Button type={"button"} label={"Radera"} clickHandler={(event) => deleteHandler(event)}></Button>
        </Wrapper>
    );
};

export default RouteForm;
